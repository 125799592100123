import {
  EnterpriseHeaderV2 as Header,
  supportLink,
  logo,
} from '@everlywell/leaves';
import loadable from '@loadable/component';
import { CheckoutProvider } from 'components/Checkout/useCheckoutContext';
import { ThemeContext, Theme } from 'components/Themable';
import React from 'react';
import GlobalStyle from 'styles/global';
import { Spinner } from 'styles/utils';
import { ProductsBySlugType } from 'utils/types';

import image from '../../images/logo/everlywell-logo.svg';
import { ProductInfoContext } from './index';
import * as S from './styles';

declare global {
  interface Window {
    appRoot: string;
    asset_host: string;
  }
}

const Footer = loadable(() => import('../Footer'), {
  fallback: <Spinner />,
});
type Props = {
  children: React.ReactNode;
  productsBySlug?: ProductsBySlugType;
};

const Layout = (props: Props) => {
  const { productsBySlug = {} } = props;

  return (
    <div id="app" className="siteRoot">
      <GlobalStyle />
      <ThemeContext.Provider value={Theme.Default}>
        <ProductInfoContext.Provider value={productsBySlug}>
          <Header>
            {{
              mobile: [
                logo({ src: image, alt: 'Everlywell' }),
                supportLink({
                  label: 'Support',
                  url: 'https://support.everlywell.com/',
                }),
              ],
              desktop: [
                logo({ src: image, alt: 'Everlywell' }),
                supportLink({
                  label: 'Support',
                  url: 'https://support.everlywell.com/',
                }),
              ],
              hidden: [],
            }}
          </Header>
          <CheckoutProvider>
            <S.CheckoutLayout>
              {props.children}
              <S.SolvvyOverrideStyles />
            </S.CheckoutLayout>
          </CheckoutProvider>
          <Footer />
        </ProductInfoContext.Provider>
      </ThemeContext.Provider>
    </div>
  );
};

export default Layout;
