import { Col } from '@everlywell/leaves';
import React, { Ref } from 'react';
import { FieldError } from 'react-hook-form';
import { CHECKOUT } from 'utils/constants/dataTest';

import ShippingInfo from './ShippingInfo';
import * as S from './styles';

export interface Props {
  register<T>(validationConfig?: {
    required?: string | boolean;
    pattern?: {
      value: RegExp;
      message: string;
    };
  }): Ref<T> | undefined;
  diffBillAddressSelected: boolean;
  onBillAddressToggle: (x: string) => void;
  errors: {
    [key: string]: FieldError;
  };
  disabledInput?: boolean;
  includeNewYork: boolean;
  stateDisclaimer: string;
}

const BillingAddressToggle: React.FC<Props> = ({
  register,
  diffBillAddressSelected,
  onBillAddressToggle,
  errors,
  disabledInput,
  stateDisclaimer,
  includeNewYork,
}: Props) => {
  const handleOptionChange = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLElement;
    onBillAddressToggle(target.id);
  };
  return (
    <>
      <S.FlexOrder order="1" mobileOrder="1">
        <label>Billing Address</label>
        <S.BillingOptionsWrapper>
          <Col xs md>
            <S.BillingOptions
              id={CHECKOUT.BILLING_ADDRESS_ID}
              data-testid={CHECKOUT.BILLING_ADDRESS_RADIO_SAME}
              name="sameBillingAddress"
              label="Same as Shipping Address"
              onChange={handleOptionChange}
              checked={!diffBillAddressSelected}
              isSelected={!diffBillAddressSelected}
            />
          </Col>
          <Col xs md>
            <S.BillingOptions
              id={CHECKOUT.DIFFERENT_BILLING_ADDRESS_ID}
              data-testid={CHECKOUT.BILLING_ADDRESS_RADIO_DIFFERENT}
              name="differentBillingAddress"
              label="Use a different Billing Address"
              onChange={handleOptionChange}
              checked={diffBillAddressSelected}
              isSelected={diffBillAddressSelected}
              disabled={disabledInput}
            />
          </Col>
        </S.BillingOptionsWrapper>
        {diffBillAddressSelected ? (
          <>
            <ShippingInfo
              isBilling
              register={register}
              errors={errors}
              includeNewYork={includeNewYork}
              stateDisclaimer={stateDisclaimer}
            />
            <S.Divider />
          </>
        ) : null}
      </S.FlexOrder>
    </>
  );
};

export default BillingAddressToggle;
