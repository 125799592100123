import React, { Ref } from 'react';
import { CHECKOUT } from 'utils/constants/dataTest';

import { CheckoutDropdown } from './styles';

export type Props = {
  register: () => Ref<HTMLSelectElement> | undefined;
  disabledInput?: boolean;
  hdyhauDropdownOptions: string[];
};

const HowDidYouHear = ({
  register,
  disabledInput,
  hdyhauDropdownOptions,
}: Props): JSX.Element => {
  /**
   * Randomize order of options to avoid biasing
   * to earlier options in list.
   */
  const randomizeOrder = (array: string[]) => {
    if (array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    }
    return [];
  };

  /**
   * Generate list of dropdown values from
   * list of options in randomized order.
   * 'Other' needs to be the last option in the list
   * so it's added in here instead of pulling directly
   * from Contentful.
   */
  const attributionDropdownValues = [
    ...randomizeOrder(hdyhauDropdownOptions)?.map((option) => ({
      id: option,
      value: option,
    })),
    {
      id: 'Other',
      value: 'Other',
    },
  ];

  return (
    <CheckoutDropdown
      items={attributionDropdownValues}
      data-testid={CHECKOUT.HOW_DID_YOU_HEAR}
      label="How did you hear about us? (Optional)"
      name="attribution"
      id="attribution"
      placeholderText=" " // ignored if passed an empty string
      ref={register()}
      disabled={disabledInput}
    />
  );
};

export default HowDidYouHear;
