import { Row, Col, Checkbox } from '@everlywell/leaves';
import StyledContentfulText from 'components/StyledContentfulText';
import { useStaticQuery, graphql } from 'gatsby';
import React, { useState, Ref } from 'react';
import { FieldError, useFormContext } from 'react-hook-form';
import { CHECKOUT } from 'utils/constants/dataTest';
import { ContentfulRichText } from 'utils/contentful/renderRichText';
import { ContentfulHtmlLongText } from 'utils/types';

import { AcceptedCards } from './AcceptedCards';
import BillingAddressToggle from './BillingAddressToggle';
import HowDidYouHear from './HowDidYouHear';
import { StripeFields } from './StripeFields';
import * as S from './styles';

interface Props {
  register<T>(validationConfig?: {
    required?: string | boolean;
    pattern?: {
      value: RegExp;
      message: string;
    };
  }): Ref<T> | undefined;
  diffBillAddressSelected: boolean;
  onBillAddressToggle: (x: string) => void;
  hidePaymentFields: boolean;
  errors: {
    [key: string]: FieldError;
  };
  sectionNumber: number;
  disabledInput?: boolean;
  hasOnlyLabVisit?: boolean;
  includeNewYork: boolean;
  stateDisclaimer: string;
  privacyPolicyDisclaimerText: ContentfulHtmlLongText;
  paymentText: ContentfulRichText;
  hdyhauDropdownOptions: string[];
}

const PaymentInfo: React.FC<Props> = ({
  register,
  diffBillAddressSelected,
  onBillAddressToggle,
  errors,
  hidePaymentFields,
  disabledInput,
  sectionNumber,
  hasOnlyLabVisit,
  includeNewYork,
  stateDisclaimer,
  privacyPolicyDisclaimerText,
  paymentText,
  hdyhauDropdownOptions,
}: Props) => {
  const [isChecked, setIsChecked] = useState(true);

  const { setValue } = useFormContext();

  const acceptedCards = useStaticQuery(graphql`
    {
      visa: contentfulAsset(title: { eq: "Visa" }) {
        title
        gatsbyImageData(placeholder: BLURRED)
      }
      masterCard: contentfulAsset(title: { eq: "Mastercard" }) {
        title
        gatsbyImageData(placeholder: BLURRED)
      }
      discover: contentfulAsset(title: { eq: "Discover" }) {
        title
        gatsbyImageData(placeholder: BLURRED)
      }
      americanExpress: contentfulAsset(title: { eq: "AmericanExpress" }) {
        title
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  `);

  const getPrivacyPolicyDisclaimer = (): JSX.Element => (
    <StyledContentfulText text={privacyPolicyDisclaimerText} />
  );

  return (
    <div data-testid={CHECKOUT.CHECKOUT_PAYMENT_INFORMATION}>
      <S.ContentContainer>
        <Row>
          <Col xs>
            <S.Title>{sectionNumber}. Payment information</S.Title>
          </Col>
        </Row>
        {!hidePaymentFields ? (
          <>
            <Row>
              {/* Card Images */}
              <AcceptedCards
                cards={Object.values(acceptedCards)}
                paymentText={paymentText}
              />
            </Row>
            <Row>
              <Col>
                <Row>
                  {/* Hide Billing Address toggle when there are ONLY in-lab kits in the cart */}
                  {!hasOnlyLabVisit ? (
                    <BillingAddressToggle
                      register={register}
                      diffBillAddressSelected={diffBillAddressSelected}
                      onBillAddressToggle={onBillAddressToggle}
                      errors={errors}
                      disabledInput={disabledInput}
                      includeNewYork={includeNewYork}
                      stateDisclaimer={stateDisclaimer}
                    />
                  ) : null}
                  {/* Credit Card Fields Supplied by Stripe */}
                  <StripeFields disabled={disabledInput} />
                </Row>
              </Col>
            </Row>
          </>
        ) : null}
        <Checkbox
          checked={isChecked}
          data-testid={CHECKOUT.OPT_IN_CHECKBOX}
          name="marketing"
          onChange={() => {
            setIsChecked(!isChecked);
            setValue('marketing', !isChecked);
          }}
          label={getPrivacyPolicyDisclaimer()}
          uid="marketing"
          ref={register()}
          bodyTextStyle
        />
        <HowDidYouHear
          register={register}
          disabledInput={disabledInput}
          hdyhauDropdownOptions={hdyhauDropdownOptions}
        />
      </S.ContentContainer>
    </div>
  );
};

export default PaymentInfo;
