import { Document } from '@contentful/rich-text-types';
import { CaptureOrderResponseBody } from '@paypal/paypal-js/types/apis/orders';
import { ContentfulRichText } from 'utils/contentful/renderRichText';
import { ContentfulHtmlLongText } from 'utils/types';

export type Order = {
  id: number;
  user_id: number;
  email: string;
  token: string;
  registration_token: string;
  state: string;
  number: string;
  line_items: LineItem[];
  gift_card_total: string;
  ship_address_id: number | null;
  bill_address_id: number | null;
  promotions: Promotion[];
  promo_total: string;
  gift_cards: GiftCard[];
  shipping: string;
  total: string;
  amount: string;
};

export type LineItem = {
  id: number;
  order_id: number;
  product_id: number;
  variant_id: number;
  variant_sku: string;
  product_name: string;
  product_sku: string;
  quantity: number;
  total: string;
  amount: string;
  base_price: string;
  is_subscription: boolean;
  subscription_frequency: string | null;
  paypal_plan_id: string | null;
  is_lab_visit: boolean;
};

export type Address = {
  city: string | null;
  country: string | null;
  first_name: string | null;
  id: number;
  last_name: string | null;
  phone: string | null;
  state: string | null;
  street1: string | null;
  street2: string | null;
  zipcode: string | null;
};

export type GiftCard = {
  adjustment_id: number;
  amount: string;
  gift_card_code: string;
  remaining_value: number;
};

export type Promotion = {
  id: number;
  code: string;
  description: string;
  line_item_discount: number | null;
  name: string;
  volume_discount_quantity: number | null;
};

export type CheckoutData = {
  email: string;
  terms: string;
  marketing: string;
  psc_id?: string | number;
  shipState?: string;
  billState?: string;
  ship_address: {
    country: string;
    type: string;
    city: string;
    first_name: string;
    last_name: string;
    phone?: string;
    state: string;
    street1: string;
    street2: string;
    zipcode: string;
  };
  smsOptIn?: boolean;
  bill_address: {
    country: string;
    type: string;
    city: string;
    first_name: string;
    last_name: string;
    phone?: string;
    state: string;
    street1: string;
    street2: string;
    zipcode: string;
  };
  cookie_data: {
    ipCookie?: string;
    campaignId?: string;
    templateId?: string;
    messageId?: string;
  };
  shipping_method_id?: string;
  attribution?: string;
};

export type CheckoutFormData = {
  email: string;
  marketing: boolean;
  psc_id?: string | number;
  shipAddress1: string;
  shipAddress2: string;
  shipCity: string;
  shipFirstName: string;
  shipLastName: string;
  shipPhone: string;
  smsOptIn?: boolean;
  shipState: string;
  shipZipcode: string;
  billAddress1: string;
  billAddress2: string;
  billCity: string;
  billFirstName: string;
  billLastName: string;
  billPhone: string;
  billState: string;
  billZipcode: string;
  attribution?: string;
};

export type PaymentDetails =
  | StripePaymentDetails
  | PayPalPaymentDetails
  | PayPalSubscriptionPaymentDetails
  | Record<string, never>;

export enum PaymentMethod {
  Stripe = 'stripe',
  PayPal = 'paypal',
  Device = 'device',
}

export type StripePaymentDetails = {
  stripe_token: string; // Stripe Token.id
  payment_method: PaymentMethod.Stripe;
  billing_agreement_id?: undefined;
};

export type PayPalPaymentDetails = {
  payment_method: PaymentMethod.PayPal;
  payment_id: string;
  payer_id: string;
  billing_agreement_id?: undefined;
};

export type PayPalSubscriptionPaymentDetails = {
  payment_method: PaymentMethod.PayPal;
  payment_id: string;
  billing_agreement_id: string;
};

export interface PayPalSubscriptionDetails extends CaptureOrderResponseBody {
  payment_id: string;
}

export type BundleItem = {
  variantId: number;
  qty: number;
};

export type BundleParamsType = {
  bundleItems: Array<BundleItem>;
  promoToApply?: string;
  orderIdParam?: string;
};

export type ErrorResponse = {
  response: {
    data: {
      errors: Array<string>;
    };
  };
};

export type PSCLocation = {
  id: string | number;
  name: string;
  distance: number;
  address: string;
  city: string;
  state: string;
  zip: string;
  hours: string;
};

export type PaymentData = {
  payerEmail: string;
  shippingAddress: {
    addressLine: string[];
    city: string;
    postalCode: string;
    region: string;
  };
  payerName: string;
  token: { id: string };
};

type TestAgeRestriction = {
  testName: string;
  ageRestriction: string;
};

type TextWithModalLink = {
  //  Long text type from Contentful returns an object with its key as the content
  textBefore: {
    textBefore: string;
  };
  modalLink: string;
  textAfter: {
    textAfter: string;
  };
};

export type ShippingMethodCopy = {
  name: string;
  minBusinessDays: number;
  maxBusinessDays: number;
};

export type AgeRestriction = {
  title: string;
  tableHeaderTest: string;
  tableHeaderAge: string;
  listOfTests: TestAgeRestriction[];
  footerText?: {
    raw: Document;
  };
};

export type PaymentText = {
  raw: Document;
};

export type CheckoutPageCopy = {
  legalNotice: TextWithModalLink;
  legalNoticeLink: ContentfulRichText;
  smsOptInCheckbox: ContentfulHtmlLongText;
  phoneHelperText: string;
  smsLegalText: ContentfulRichText;
  stateDisclaimer: string;
  shippingNoticeBefore: string;
  shippingNoticeAfter: string;
  shippingMethods: ShippingMethodCopy[];
  privacyPolicyDisclaimer: ContentfulHtmlLongText;
  paymentText: ContentfulRichText;
  ageRestriction: AgeRestriction;
  hdyhauDropdownOptions: string[];
};
