/* eslint-disable no-useless-escape */

import ewAnalytics from 'utils/analytics';
import {
  ITERABLE_API_URL,
  ITERABLE_CLIENT_SIDE_API_KEY,
  ITERABLE_TOKEN,
  ITERABLE_URL,
} from 'utils/constants/iterable';
import { showFlash } from 'utils/domHelpers';
import { isValidEmail } from 'utils/formHelpers';

import { ANALYTICS } from './constants/analytics';
import { logError } from './helpers';

const subscribe = (email: string, userId?: string): void => {
  const iterableURL = ITERABLE_URL;
  const analyticsAuth = `Basic ${ITERABLE_TOKEN}`;

  // Iterable Metadata
  const baseEventData = {
    dataFields: {
      emailCollectionModal: email,
    },
    email,
    eventName: 'emailCollectionModal',
  };
  const baseSyncData = {
    emailData: {
      email,
      newEmail: email,
    },
    eventData: baseEventData,
    eventType: 'EmailCollectionModal',
  };

  const syncData = userId
    ? { ...baseSyncData, eventData: { ...baseEventData, userId } }
    : baseSyncData;

  // Submit email to iterable
  fetch(`${iterableURL}/sync-event`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: analyticsAuth,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(syncData),
  })
    .then(() => showFlash('alert-success', 'Thanks for subscribing!'))
    .catch(() =>
      showFlash(
        'alert-error',
        'Unable to subscribe, please try an alternate email.',
      ),
    );
};

/**
 * Removes any special characters and adds the US country code
 * @param phoneNUmber phone number to format
 * @returns
 */
const formatPhoneNumberForIterable = (phoneNUmber: string) =>
  `+1${phoneNUmber.replace(/\D/gi, '')}`;

/**
 * Email is also required for sms susbscription
 */
const subscribeSms = async (phoneNumber: string, email: string) => {
  if (!ITERABLE_CLIENT_SIDE_API_KEY || !ITERABLE_API_URL) {
    if (!ITERABLE_CLIENT_SIDE_API_KEY) {
      logError(
        'Missing environment variable: Iterable client side api key',
        undefined,
      );
    }

    if (!ITERABLE_API_URL) {
      logError('Missing environment variable: Iterable api url', undefined);
    }

    return;
  }

  const formatedPhoneNumber = formatPhoneNumberForIterable(phoneNumber);

  var requestBody = {
    email,
    dataFields: {
      phoneNumber: formatedPhoneNumber,
      smsCheckoutOptIn: true,
    },
  };

  try {
    const response = await fetch(`${ITERABLE_API_URL}/users/update`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        api_key: ITERABLE_CLIENT_SIDE_API_KEY,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    if (response.ok) {
      ewAnalytics.track({
        event: ANALYTICS.EVENTS.SMS_SUBSCRIPTION,
        data: {
          label: 'Successful sms opt-in',
        },
      });
    }
  } catch (error) {
    logError('Error with sms subscription', undefined);
  }
};

const handleIterableSubscription = (
  email: string,
  userId?: string,
): void | null | undefined => {
  if (isValidEmail(email)) {
    return userId ? subscribe(email, userId) : subscribe(email);
  }
  return showFlash('alert-error', 'Please enter a valid email.');
};

export { handleIterableSubscription, subscribe, subscribeSms };
