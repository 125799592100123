import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { H3, Modal } from '@everlywell/leaves';
import React, { Dispatch, SetStateAction } from 'react';
import { CHECKOUT } from 'utils/constants/dataTest';
import { sanitize } from 'utils/domHelpers';

import { AgeRestriction } from '../types';
import * as S from './styles';

export type Props = {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  ageRestriction: AgeRestriction;
};

const AgeRestrictionModal = ({
  isModalOpen,
  setIsModalOpen,
  ageRestriction,
}: Props): JSX.Element => {
  const { title, tableHeaderTest, tableHeaderAge, listOfTests, footerText } =
    ageRestriction;

  const ModalFooter = (): JSX.Element | null => {
    if (!footerText) {
      return null;
    }

    const footerHtmlContent = documentToHtmlString(
      JSON.parse(`${footerText.raw}`),
    );
    return (
      <S.ModalFooter
        dangerouslySetInnerHTML={{
          __html: sanitize(footerHtmlContent),
        }}
      ></S.ModalFooter>
    );
  };

  return (
    <Modal
      open={isModalOpen}
      openHandler={setIsModalOpen}
      data-testid={CHECKOUT.AGE_RESTRICTION_MODAL}
    >
      <H3>{title}</H3>

      <S.TestsTable>
        <thead>
          <tr>
            <th>{tableHeaderTest}</th>
            <th>{tableHeaderAge}</th>
          </tr>
        </thead>
        <tbody>
          {listOfTests.map((restriction) => (
            <tr key={restriction.testName}>
              <td>{restriction.testName}</td>
              <td>{restriction.ageRestriction}</td>
            </tr>
          ))}
        </tbody>
      </S.TestsTable>

      <ModalFooter />
    </Modal>
  );
};
export default AgeRestrictionModal;
