import {
  size,
  Container,
  typography,
  colors,
  mediaQueries,
} from '@everlywell/leaves';
import styled from 'styled-components';

const PscResultsContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: ${size.lg}px 0;
  border: ${size.xs3}px solid ${colors.green1};
  border-radius: ${size.xs2}px;
  padding: ${size.xl3}px ${size.md}px;
`;

const PscListContainer = styled.div`
  height: 500px;
  margin: ${size.lg}px 0;
  overflow: scroll;
`;

const PscLocationContainer = styled.div<{ hasSelectedPsc: boolean }>`
  display: flex;
  flex-direction: row;
  padding: ${size.xs1}px;
  width: 100%;
  height: auto;
  border: ${(props) =>
    props.hasSelectedPsc
      ? `${size.xs3}px solid ${colors.green5}`
      : `${size.xs3}px solid ${colors.green1}`};
  box-sizing: border-box;
  border-radius: ${size.xs3}px;
  margin: ${size.md}px 0;
  cursor: pointer;
  background-color: ${(props) =>
    props.hasSelectedPsc ? colors.green1 : 'none'};
`;

const PscLocationNumberContainer = styled.div`
  text-align: center;
  width: ${size.xl3}px;
  margin-top: ${size.xs3}px;
`;

const PscLocationNumber = styled.p<{ hasSelectedPsc: boolean }>`
  display: flex;
  width: ${size.xl1}px;
  height: ${size.xl1}px;
  padding: ${size.xs1}px;
  justify-content: center;
  border-radius: 100%;
  line-height: ${size.md}px;
  background-color: ${(props) =>
    props.hasSelectedPsc ? colors.green5 : colors.green1};
  color: ${(props) => (props.hasSelectedPsc ? colors.white : colors.green5)};
`;

const PscDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: ${size.xs2}px;
  ${typography.bodyTextSmall}

  ${mediaQueries.forTabletHorizontalUp} {
    font-size: 100%;
  }
`;

const NameAndDistance = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .distance-text {
    font-weight: ${typography.weight.bold};
  }
`;

const LocationDetailsText = styled.p`
  line-height: ${size.md}px;
`;

const LocationHoursText = styled.p`
  line-height: ${size.lg}px;
`;

const LabAddressContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const LocationsFound = styled.div`
  display: flex;
  ${typography.captionText}
  flex-direction: row-reverse;
`;

export {
  PscResultsContainer,
  PscListContainer,
  PscLocationContainer,
  PscLocationNumber,
  PscDetailsContainer,
  NameAndDistance,
  LocationDetailsText,
  LocationHoursText,
  LocationsFound,
  LabAddressContainer,
  PscLocationNumberContainer,
};
