import { logError } from 'utils/helpers';

import { BundleItem, LineItem, Order } from '../../components/Checkout/types';
import { getVariantId } from '../helpers';
import { categoryProductSKUMap } from '../hooks/useProductsByCategory/membershipProductSkuMap';
import {
  ActiveMembershipStatuses,
  MembershipStatus,
  MembershipType,
  MembershipTypeOptions,
} from '../types';

export const filterPromosForMemberships = (
  active: boolean,
  slug: string,
  state?: MembershipStatus,
) => {
  if (!active) return false;
  const isMember = state && ActiveMembershipStatuses.includes(state);
  return !(slug.includes('membership') && isMember);
};
export const shouldRedirectMember = (bundleItems: Array<BundleItem>) => {
  const membershipVariants = bundleItems
    .filter((item) => item.variantId === 504 || item.variantId === 374)
    .map((item) => item.variantId);
  // TODO Remove hardcoded redirect route and receive redirect URL from backend
  // Current membership variant id: 374
  // Control membership variant id: 504
  return membershipVariants.includes(504) || membershipVariants.includes(374);
};
export const hasMembershipLineItem = (order: Order): boolean =>
  order.line_items.some((li) => [504, 374].includes(li.variant_id));
export const membershipLineItem = (order: Order): LineItem | undefined =>
  order.line_items.find((li) => isMembershipVariantId(li.variant_id));
export const isMembershipVariantId = (id: number): boolean =>
  [504, 374].includes(id);
export const getMembershipVariantId = (
  testSKU: string,
  membershipType: MembershipType,
): number | null => {
  try {
    const memberShipCategory =
      membershipType === MembershipTypeOptions.current
        ? categoryProductSKUMap?.sti
        : categoryProductSKUMap?.control;
    const variantId = getVariantId(memberShipCategory, testSKU || '') ?? '';

    if (!variantId) throw new Error('Variant not found');
    return variantId;
  } catch (err) {
    logError((err as Error).message, {
      component: 'membershipHelpers',
      method: 'getMembershipVariantId',
    });
    return null;
  }
};
