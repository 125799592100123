import { colors, size } from '@everlywell/leaves';
import styled from 'styled-components';

export const ModalFooter = styled.div`
  padding: ${size.md}px 0;

  a {
    color: ${colors.blue1};
    text-decoration: none;

    :hover,
    :active {
      text-decoration: underline;
    }
  }
`;

export const TestsTable = styled.table`
  margin: ${size.md}px 0;
  text-align: left;
  width: 100%;

  thead {
    background-color: ${colors.green1};
    color: ${colors.green5};
  }

  tr {
    border-bottom: 2px solid ${colors.green2};
  }

  th,
  td {
    padding: ${size.lg}px ${size.xs1}px;
  }
`;
