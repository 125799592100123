import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {
  cleanupOrder,
  fetchCurrentOrder,
  getBundledParams,
  isEmptyOrder,
} from 'components/Checkout/checkoutHelpers';
import { CheckoutPageCopy } from 'components/Checkout/types';
import { graphql, navigate } from 'gatsby';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
import ewAnalytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';
import { getProductsBySlug } from 'utils/helpers';
import { UserContext } from 'utils/hooks/useUserContext/context';

import Layout from '../components/Layout/checkout';
import { SEO } from '../components/SEO';
import { CheckoutContainer } from '../containers/CheckoutContainer/index';
import { shouldRedirectMember } from '../utils/helpers/membershipHelpers';
import { MembershipsRedirect, MembershipTypeOptions } from '../utils/types';

const stripePromise = loadStripe(`${process.env.STRIPE_PUBLIC_KEY}`);

type Props = {
  data: {
    // TODO: https://everlyhealth.atlassian.net/browse/CG-1516
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    allSpreeProduct: any;
    contentfulCheckoutPage: CheckoutPageCopy;
  };
};

const CheckoutPage: FC<Props> = (props: Props) => {
  const {
    userData: {
      activeCredits,
      fetched,
      firstName,
      lastName,
      isMember,
      loggedIn,
      membershipType,
      nextCreditDate,
      userEmail,
      userId,
      membershipState,
    },
  } = useContext(UserContext);
  const [orderId, setOrderId] = useState<number>();

  const [orderIdParam] = useQueryParam('orderId', NumberParam);
  const [failedOrderParam] = useQueryParam('failedOrder', StringParam);
  const abandonedCart = orderIdParam || failedOrderParam;

  // populates product context from Contentful, needed for line item images
  const { data } = props;
  const { allSpreeProduct, contentfulCheckoutPage } = data;
  const { nodes } = allSpreeProduct;
  const productsBySlug = getProductsBySlug(nodes);

  useEffect(() => {
    ewAnalytics.track({
      event: ANALYTICS.EVENTS.VIEWED_PAGE,
      data: {
        Page: ANALYTICS.LABELS.CHECKOUT,
      },
    });
  }, []);

  useEffect(() => {
    // Fetch the current order in LocalStorage - CheckoutContainer will use the orderId to fetch the order from API
    fetchCurrentOrder()
      .then((order) => {
        setOrderId(order.id);
        if (window.location) {
          const { bundleItems } = getBundledParams(window.location.search);
          if (isEmptyOrder(order) && !bundleItems.length && !abandonedCart) {
            navigate('/');
          }
        }
      })
      .catch((error) => {
        navigate('/');
      });
  }, [abandonedCart]);

  useEffect(() => {
    const { bundleItems } = getBundledParams(window.location.search);
    if (bundleItems.length && loggedIn && isMember && membershipType?.length) {
      if (shouldRedirectMember(bundleItems)) {
        const redirectTarget =
          membershipType === MembershipTypeOptions.current
            ? MembershipsRedirect.current
            : MembershipsRedirect.control;
        cleanupOrder();
        navigate(`/${redirectTarget}`, {
          state: {
            notifyCurrentMember: true,
          },
        });
      }
    }
  }, [
    activeCredits,
    fetched,
    firstName,
    lastName,
    isMember,
    loggedIn,
    membershipType,
    nextCreditDate,
    userEmail,
    userId,
    membershipState,
  ]);

  return (
    <>
      <Layout productsBySlug={productsBySlug}>
        <SEO title="Checkout" />
        <Elements stripe={stripePromise}>
          {orderId ? (
            <CheckoutContainer
              orderId={orderId}
              checkoutCopy={contentfulCheckoutPage}
            />
          ) : null}
        </Elements>
      </Layout>
    </>
  );
};

export default CheckoutPage;

export const data = graphql`
  query {
    allSpreeProduct {
      nodes {
        slug
        productId
        contentfulProductInfo {
          productCardImage {
            gatsbyImageData(placeholder: BLURRED, quality: 75)
          }
          boxImage {
            gatsbyImageData(placeholder: BLURRED)
          }
          displayName
          slug
        }
        master_variant_id
        variants {
          price
          is_master
          id
        }
      }
    }
    contentfulCheckoutPage {
      legalNotice {
        textBefore {
          textBefore
        }
        modalLink
        textAfter {
          textAfter
        }
      }
      legalNoticeLink {
        raw
      }
      smsOptInCheckbox {
        childMarkdownRemark {
          html
        }
      }
      phoneHelperText
      smsLegalText {
        raw
      }
      stateDisclaimer
      shippingNoticeBefore
      shippingNoticeAfter
      shippingMethods {
        name
        minBusinessDays
        maxBusinessDays
      }
      privacyPolicyDisclaimer {
        childMarkdownRemark {
          html
        }
      }
      paymentText {
        raw
      }
      ageRestriction {
        title
        tableHeaderTest
        tableHeaderAge
        listOfTests {
          testName
          ageRestriction
        }
        footerText {
          raw
        }
      }
      hdyhauDropdownOptions
    }
  }
`;
