import moment, { Moment } from 'moment';

const WEEKEND_INDEXES = [6, 7];

const isWeekend = (date: Moment): boolean =>
  WEEKEND_INDEXES.includes(date.isoWeekday());

const getCurrentDateTimeCentral = () =>
  moment(
    new Date(
      new Date().toLocaleString('en-US', { timeZone: 'America/Chicago' }),
    ),
  );

const getChristmasDeadLine = () => {
  const CURRENT_YEAR = new Date().getFullYear();
  return moment(new Date(`${CURRENT_YEAR}-12-21T13:00:00`));
};

const getDayBeforeChristmasEve = () => {
  const CURRENT_YEAR = new Date().getFullYear();
  return moment(new Date(`${CURRENT_YEAR}-12-23`));
};
/**
 * Adds a number of week days to a date (skips weekend days)
 * @param days number of days to add
 * @param date the date to base the calculations (defaults to current date)
 * @returns the date provided + a number of day(s) added
 */
export const addWeekDays = (days: number, date = moment()): Moment => {
  date = moment(date);

  // keep adding a day while days is not zero
  while (days > 0) {
    date.add(1, 'day');
    if (!isWeekend(date)) {
      days -= 1;
    }
  }

  return date;
};

export const getWeekDay = (date: Moment): string => date.format('dddd');

export const getDaysToDelivery = (
  christmasDeadLine = getChristmasDeadLine(),
  dayBeforeChristmasEve = getDayBeforeChristmasEve(),
  currentCentralTime = getCurrentDateTimeCentral(),
): number => {
  if (
    currentCentralTime.isBefore(christmasDeadLine) ||
    currentCentralTime.isAfter(dayBeforeChristmasEve)
  ) {
    return 2;
  }

  if (currentCentralTime.isSame(dayBeforeChristmasEve, 'day')) {
    return 3;
  }

  return 4;
};

export const isAdult = (dob: string): boolean => {
  // dob should follow YYYY-MM-DD format
  const rawDate = dob.replace(/-/g, '');
  const year = rawDate.substring(0, 4);
  const month = rawDate.substring(4, 6);
  const day = rawDate.substring(6, 8);
  return new Date(+year + 18, +month - 1, +day) <= new Date();
};
