import { Row, Col, Help, typography, colors } from '@everlywell/leaves';
import { useElements } from '@stripe/react-stripe-js';
import { StripeElementStyle } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import { CHECKOUT } from 'utils/constants/dataTest';

import { ANALYTICS } from '../../utils/constants/analytics';
import { logFormInteraction } from './AnalyticsHelpers';
import * as S from './styles';

interface Props {
  membershipCheckout?: boolean;
  disabled?: boolean;
}

export const StripeFields: React.FC<Props> = ({
  membershipCheckout = false,
  disabled,
}) => {
  const [formStarted, setFormStarted] = useState(false);
  const elements = useElements();

  const beginFormInteraction = () => {
    if (!formStarted) {
      setFormStarted(true);
      logFormInteraction(
        membershipCheckout
          ? ANALYTICS.LABELS.MEMBERSHIP_INTAKE.PAYMENT_INFO.COMPONENT
          : ANALYTICS.LABELS.CHECKOUT,
      );
    }
  };

  useEffect(() => {
    if (elements) {
      const { create, getElement } = elements;

      // Shared element styles
      const style: StripeElementStyle = {
        base: {
          color: colors.gray4,
          fontFamily: typography.type.nexa,
          lineHeight: '1.78',
          fontSize: '18px',
          fontSmoothing: 'antialiased',
          '::placeholder': {
            color: colors.gray3,
            fontWeight: typography.weight.book,
          },
        },
        invalid: {},
      };

      // Create and mount form fields
      const cardNumber =
        getElement('cardNumber') ||
        create('cardNumber', {
          showIcon: false,
          placeholder: '0000-0000-0000-0000',
          style,
        });
      cardNumber.mount('#creditCardNumber');

      const cardExpiry =
        getElement('cardExpiry') ||
        create('cardExpiry', {
          placeholder: 'MM/YY',
          style,
        });
      cardExpiry.mount('#creditCardExpiration');

      const cardCvc =
        getElement('cardCvc') ||
        create('cardCvc', {
          placeholder: '000',
          style,
        });
      cardCvc.mount('#creditCardCvc');

      cardNumber.on('change', beginFormInteraction);
      cardExpiry.on('change', beginFormInteraction);
      cardCvc.on('change', beginFormInteraction);
    }
  }, [elements]);

  useEffect(() => {
    if (elements) {
      const { getElement } = elements;
      const cardNumber = getElement('cardNumber');
      const cardExpiry = getElement('cardExpiry');
      const cardCvc = getElement('cardCvc');

      // Disable Stripe payment inputs when submitting order
      if (disabled) {
        cardNumber?.update({ disabled: true });
        cardExpiry?.update({ disabled: true });
        cardCvc?.update({ disabled: true });
      } else {
        cardNumber?.update({ disabled: false });
        cardExpiry?.update({ disabled: false });
        cardCvc?.update({ disabled: false });
      }
    }
  }, [disabled]);

  return (
    <>
      <S.FlexOrder order="2" mobileOrder="2">
        <S.StripeInputWrapper>
          <S.StripeInput
            id="creditCardNumber"
            data-testid={CHECKOUT.CARD_NUMBER_INPUT}
            name="Card Number"
          />
          <S.StripeLabel htmlFor="Card Number">Card Number</S.StripeLabel>
        </S.StripeInputWrapper>
      </S.FlexOrder>
      <S.FlexOrder order="3" mobileOrder="3">
        <Row>
          <Col xs md>
            <S.StripeInputWrapper>
              <S.StripeInput
                id="creditCardExpiration"
                name="Expiration Date"
                data-testid={CHECKOUT.EXPIRATION_DATE_INPUT}
              />
              <S.StripeLabel htmlFor="Expiration Date">
                Expiration Date
              </S.StripeLabel>
            </S.StripeInputWrapper>
          </Col>
          <Col xs md>
            <S.StyledInputContainer>
              <S.StripeInputWrapper>
                <S.StripeInput
                  id="creditCardCvc"
                  name="CVC"
                  data-testid={CHECKOUT.CVC_INPUT}
                />
                <S.StripeLabel htmlFor="CVC">CVC</S.StripeLabel>
              </S.StripeInputWrapper>
              <S.TooltipWrapper>
                <S.CvcTooltip
                  animationSpeed="normal"
                  content="3-digit security code usually found on the back of your card. American Express cards have a 4-digit code located on the front."
                  position="top"
                  arrow="right"
                  tooltipBoxClass="tooltipBox"
                >
                  <Help />
                </S.CvcTooltip>
              </S.TooltipWrapper>
            </S.StyledInputContainer>
          </Col>
        </Row>
      </S.FlexOrder>
    </>
  );
};
