import React from 'react';
import ewAnalytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';
import { CHECKOUT } from 'utils/constants/dataTest';

import * as S from './styles';

export type Props = {
  isSubmitting: boolean;
  isLoading: boolean;
};

export const PlaceOrderButton = ({
  isSubmitting,
  isLoading,
}: Props): JSX.Element => {
  const trackClick = () => {
    ewAnalytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: ANALYTICS.LABELS.PLACE_ORDER,
      },
    });
  };

  return (
    <S.OrderButtonWrapper>
      <S.OrderButton
        type="submit"
        appearance="primary"
        data-testid={CHECKOUT.PLACE_ORDER_BUTTON}
        disabled={isSubmitting || isLoading}
        isLoading={isLoading}
        onClick={trackClick}
      >
        Place Order
      </S.OrderButton>
    </S.OrderButtonWrapper>
  );
};
