import {
  SkeletonLoader,
  SkeletonProps,
  SkeletonUnit,
} from '@everlywell/leaves';
import {
  OnApproveActions,
  OnApproveData,
  CreateOrderActions,
} from '@paypal/paypal-js/types/components/buttons';
import {
  PayPalButtons,
  FUNDING,
  usePayPalScriptReducer,
} from '@paypal/react-paypal-js';
import React from 'react';
import { CHECKOUT } from 'utils/constants/dataTest';

export type Props = {
  createOrder: (_: unknown, actions: CreateOrderActions) => Promise<string>;
  onApprove: (data: OnApproveData, actions: OnApproveActions) => Promise<void>;
  trackClick: () => void;
  onShippingChange: (data?: any, actions?: any) => Promise<void>;
  hasOrder: boolean;
};

const HEIGHT = 48;

const PayPalButtonWrapper = ({
  createOrder,
  onApprove,
  trackClick,
  onShippingChange,
  hasOrder,
}: Props): JSX.Element => {
  const [{ isPending }] = usePayPalScriptReducer();

  const placeholderConfig: SkeletonProps = {
    height: {
      value: HEIGHT,
      unit: SkeletonUnit.Pixel,
    },
    width: {
      value: 100,
      unit: SkeletonUnit.Percentage,
    },
  };

  return isPending || !hasOrder ? (
    <SkeletonLoader
      testId={CHECKOUT.PAY_PAL_PLACEHOLDER}
      height={placeholderConfig.height}
      width={placeholderConfig.width}
    />
  ) : (
    <PayPalButtons
      fundingSource={FUNDING.PAYPAL}
      createOrder={createOrder}
      onApprove={onApprove}
      onClick={() => trackClick()}
      onShippingChange={onShippingChange}
      style={{
        height: HEIGHT,
        label: 'checkout',
        shape: 'rect',
      }}
    />
  );
};

export default PayPalButtonWrapper;
