import React, { FC } from 'react';
import { CHECKOUT } from 'utils/constants/dataTest';

import * as S from './styles';

type Props = {
  isSubmitting: boolean;
};

const LoadingAnimation: FC<Props> = ({ isSubmitting }) => (
  <div>
    {isSubmitting ? (
      <S.ProgressCircleAnimation data-testid={CHECKOUT.LOADING_ANIMATION} />
    ) : null}
  </div>
);

export default LoadingAnimation;
