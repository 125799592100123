import {
  OnApproveActions,
  OnApproveData,
  CreateOrderActions,
} from '@paypal/paypal-js/types/components/buttons';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import ewAnalytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';

import PayPalButtonWrapper from './PayPalButtonWrapper';
import {
  purchaseUnitFromOrder,
  updateFormData,
  formatPayPalPaymentDetails,
} from './PayPalHelpers';
import * as S from './styles';
import { Order, PaymentMethod } from './types';

type Props = {
  order: Order | undefined;
  onSuccess: Function;
  onError: Function;
};

export const PayPalSection: FC<Props> = ({
  order: spreeOrder,
  onSuccess,
  onError,
  order,
}) => {
  const { setValue, trigger } = useFormContext();
  /**
   * Function called after the PayPal button is clicked and the user has
   * logged in.
   *
   * @param _ provided by PayPal
   * @param actions provided by PayPal
   * @returns
   */
  const createOrder = async (_: unknown, actions: CreateOrderActions) => {
    const purchase_units = purchaseUnitFromOrder(spreeOrder);
    return await actions.order.create({
      purchase_units,
    });
  };

  /**
   * Function called when the PayPal window is open.
   * It sends back a data object that contains some shipping info
   * for the purpose of validation. It does not provide the full address
   * until the order has been approved.
   *
   * @param data provided by PayPal
   * @param actions provided by PayPal
   * @returns Promise<void>
   */
  const onShippingChange = async (data?: any, actions?: any) => {
    // TODO: Get rid of this IF block when the types are fixed
    // This basically does nothing ATM.
    if (data && actions) {
      //TODO: Should do some kind of validation here.
      // Country, state, etc. shipping valdation would be good.
      actions.resolve();
    } else {
      // Rejecting the promise causes a "this seller cannot ship to this address"
      // wanring in the PayPal window.
      actions.reject();
    }
  };

  /**
   * Function called after the user has successfully "submitted" their info via paypal
   * @param _ provided by PayPal
   * @param actions provided by PayPal
   */
  const onApprove = async (data: OnApproveData, actions: OnApproveActions) => {
    try {
      // The PayPal TS Types implementation really sucks.
      // There is no `get` method listed, but it's there.
      // @ts-ignore
      const orderData = await actions.order.get(data.orderID);
      const paypalDetails = formatPayPalPaymentDetails(orderData);
      updateFormData(orderData, setValue, trigger);
      onSuccess(PaymentMethod.PayPal, paypalDetails);
    } catch (err) {
      onError(err);
    }
  };

  const trackClick = () => {
    ewAnalytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: ANALYTICS.LABELS.PAYPAL_PURCHASE,
      },
    });
  };

  return (
    <S.PayPalButtonsContainer data-testid={`paypal-checkout-button`}>
      <PayPalScriptProvider
        options={{
          'client-id': process.env.PAYPAL_CLIENT_ID || 'sb',
          commit: false,
          debug: process.env.PAYPAL_DEBUG || false,
        }}
      >
        <PayPalButtonWrapper
          createOrder={createOrder}
          onApprove={onApprove}
          trackClick={trackClick}
          onShippingChange={onShippingChange}
          hasOrder={!!order}
        ></PayPalButtonWrapper>
      </PayPalScriptProvider>
    </S.PayPalButtonsContainer>
  );
};

export default PayPalSection;
