import {
  Col,
  size,
  mediaQueries,
  H3,
  Dropdown,
  colors,
  typography,
  Container,
  Row,
  RadioButton,
  Tooltip,
  Button,
  Icons,
} from '@everlywell/leaves';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

type ContentContainerProps = {
  useDifferentBillingAddress?: boolean;
};
const ContentContainer = styled(Container)<ContentContainerProps>`
  margin-bottom: ${size.xl2}px;
  margin-top: ${size.xl3}px;
  padding: ${(props) =>
    props.useDifferentBillingAddress ? '0' : `0 ${size.md}px`};
  ${mediaQueries.forTabletVerticalUp} {
    margin-top: 43px;
  }
`;

const DisclosureContainer = styled(Container)`
  padding: 0 ${size.md}px;
`;

type AltPaymentsProps = {
  allowClick: boolean;
};

const AltPayments = styled.div<AltPaymentsProps>`
  display: flex;
  justify-content: space-between;
  margin: 0 ${size.md}px;
  position: relative;
  z-index: 1;
  ${mediaQueries.forTabletVerticalUp} {
    margin: 0 29px;
  }

  pointer-events: ${(props) => (props.allowClick ? 'unset' : 'none')};
`;

const CheckoutButton = styled.div`
  width: 49%;
  text-align: center;
  height: ${size.xl3}px;
`;

const PayPalButtonsContainer = styled.div``;

const CardLayout = styled(Col)`
  padding: 0;
  margin: ${size.lg}px 0;

  ${mediaQueries.forTabletHorizontalUp} {
    background: ${colors.white};
    box-shadow: 4px 4px 15px -5px rgb(0 0 0 / 10%);
    padding: ${size.xl3}px ${size.lg}px;
    border-radius: 1px;
    margin: ${size.lg}px 0;
  }
`;

const CardContent = styled(Row)`
  margin: ${size.xl2}px 8px;

  ${mediaQueries.forTabletHorizontalUp} {
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1440px;
  }
`;

const OrderSummaryContainer = styled(Col)`
  margin-top: ${size.lg}px;
`;

const Title = styled(H3)`
  padding-bottom: ${size.lg}px;
`;

const FlexGrowCol = styled(Col)`
  flex-grow: 2;
  ${mediaQueries.forTabletHorizontalUp} {
    flex-grow: 1;
  }
`;

type FlexOrderProps = {
  mobileOrder: string;
  order: string;
};
const FlexOrder = styled(Col)<FlexOrderProps>`
  order: ${(props) => props.mobileOrder};
  margin-bottom: ${size.sm}px;
  ${mediaQueries.forTabletHorizontalUp} {
    order: ${(props) => props.order};
  }
`;

const PaymentMethods = styled(Col)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${size.lg}px;
  ${mediaQueries.forTabletHorizontalUp} {
    justify-content: flex-start;
  }
`;

const PaymentMethodItem = styled.div`
  min-height: 35px;
  min-width: 45px;
  font-size: 10px;
  background: ${colors.green1};
  color: ${colors.green5};
  font-weight: ${typography.weight.bold};
  margin-right: ${size.lg}px;
  border-radius: 3px;
  padding: 0 16px;
  ${mediaQueries.forTabletHorizontalUp} {
    ${typography.bodyTextSmall};
    color: ${colors.green5};
    font-weight: ${typography.weight.bold};
    height: 37px;
    width: 52px;
  }
`;

const CustomGatsbyImageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 400px;
`;

const CustomGatsbyImage = styled(GatsbyImage)`
  flex: 1 0 15%;
  border-radius: 3px;
  margin-right: ${size.lg}px;
  height: 35px;
  min-width: 50px;
  ${mediaQueries.forTabletHorizontalUp} {
    flex: 0;
    height: 37px;
    width: 52px;
  }
`;

const PaymentMethodTextWrapper = styled.div`
  margin-top: ${size.sm}px;
  a {
    text-decoration: none;
    color: ${colors.green5};
    font-weight: ${typography.weight.bold};
  }
`;

const PaymentMethodText = styled.span`
  flex: 1 0 15%;
  color: ${colors.gray4};
  padding-top: ${size.sm}px;
  ${mediaQueries.forTabletHorizontalUp} {
    padding-top: 0;
  }
`;

const BillingOptionsWrapper = styled(Row)`
  flex-direction: column;
  margin-bottom: ${size.lg}px;

  ${mediaQueries.forTabletHorizontalUp} {
    flex-direction: row;
  }
`;

type BillingOptionsProps = {
  isSelected: boolean;
};
const BillingOptions = styled(RadioButton)<BillingOptionsProps>`
  border: 1px solid ${colors.green2};
  padding: ${size.lg}px ${size.md}px;
  box-shadow: 4px 4px 15px -5px rgb(0 0 0 / 10%);
  margin-bottom: ${size.md}px;
  background-color: ${(props) =>
    props.isSelected ? colors.green1 : colors.white};

  ${mediaQueries.forTabletHorizontalUp} {
    margin-bottom: 0;
  }
`;

const CheckoutDropdown = styled(Dropdown)`
  margin-bottom: ${size.lg}px;
  select {
    background-color: white;
  }
  select:focus {
    border: 1px solid ${colors.gray2};
    border-bottom: 1px solid ${colors.green2};
    box-shadow: ${colors.teal5} 0 0 0 1px inset;
  }
  &.state-default select {
    color: ${colors.gray3};
  }
  &.state-selected select {
    color: ${colors.gray4};
  }
`;

const CheckboxText = styled.span`
  ${typography.bodyTextSmall};
`;

const Divider = styled.div`
  border-bottom: 1px solid ${colors.green2};
  margin-bottom: ${size.lg}px;
  margin-top: -${size.md}px;
`;

const StyledInputContainer = styled.form`
  position: relative;
  padding-bottom: ${size.sm}px;
  margin-bottom: ${size.lg}px;

  ${mediaQueries.forTabletHorizontalUp} {
    padding-bottom: ${size.sm}px;
    margin-bottom: 0;
  }
`;

const TooltipWrapper = styled.div`
  position: absolute;
  right: 2%;
  top: 40%;
  color: ${colors.green4};
  ${mediaQueries.forTabletHorizontalDown} {
    top: 40%;
    margin-right: 10px;
  }
  ${mediaQueries.forPhoneOnly} {
    top: 40%;
    margin-right: 10px;
  }
`;

const CvcTooltip = styled(Tooltip)`
  .tooltipBox {
    width: 295px;
    height: 110px;
    padding: ${size.xs1}px ${size.sm}px;
    ${mediaQueries.forPhoneOnly} {
      width: 150px;
      height: 210px;
      padding: ${size.xs1}px ${size.sm}px;
    }
  }
`;

const InputButtonBG = styled.div`
  background: ${colors.white};
  height: ${size.xl1}px;
  position: absolute;
  right: 9px;
  top: 9px;
  width: 112px;
`;

const InputButton = styled(Button)`
  position: absolute;
  right: ${size.xs1}px;
  top: 9px;
  width: 113px;
`;

const StyledLabel = styled.label`
  display: block;
  margin-bottom: ${size.sm}px;
  font-size: 13px;
`;

const OrderButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`;

type OrderButtonProps = {
  type: string;
  disabled: boolean;
};
const OrderButton = styled(Button)<OrderButtonProps>`
  width: 100%;
  margin: 0 ${size.md}px;

  ${mediaQueries.forTabletHorizontalUp} {
    width: 408px;
    margin: 0;
  }
`;

const GiftCardLink = styled.span`
  ${typography.buttonText}
  cursor: pointer;
  display: flex;
`;

const StripeInputWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;

const StripeLabel = styled.label`
  font-weight: ${typography.weight.bold};
  font-family: ${typography.type.nexa};
  font-size: ${size.md}px;
  color: ${colors.gray4};
  line-height: 1.5;
  letter-spacing: normal;
`;

type StripeInputProps = {
  name: string;
};
const StripeInput = styled.div<StripeInputProps>`
  color: ${colors.gray4};
  font-family: ${typography.type.nexa};
  font-size: 18px;
  line-height: 1.78;
  background: ${colors.white};
  border-radius: 1px;
  padding: ${size.xs1}px ${size.md}px;
  border: 1px solid ${colors.green2};
  box-shadow: 4px 4px 15px -5px rgba(0, 0, 0, 0.1);
  &.StripeElement--focus {
    border: 1px solid ${colors.gray2};
    border-bottom: 1px solid ${colors.teal5};
    box-shadow: ${colors.teal5} 0 0 0 1px inset;
    + ${StripeLabel} {
      color: ${colors.teal4};
    }
  }
  &.StripeElement--invalid {
    border: 1px solid ${colors.gray2};
    border-bottom: 1px solid ${colors.red3};
    box-shadow: ${colors.red3} 0 0 0 1px inset;
    + ${StripeLabel} {
      color: ${colors.red3};
    }
  }
`;

const LineItemWrapper = styled.div`
  border-bottom: 1px solid ${colors.gray2};
  display: flex;
  justify-content: space-between;
  padding: 28px 0 22px 0;
  width: 100%;

  &:first-child {
    padding: 0 0 22px 0;
  }
  &:last-child {
    border-bottom: none;
  }
`;

const LineItemDetailsWrapper = styled.div`
  color: ${colors.gray4};
  display: flex;
  font-family: ${typography.type.nexa};
  font-size: ${size.md}px;
  font-weight: normal;
  justify-content: space-between;
  line-height: 1.5;
  width: 100%;
`;

const LineItemDetails = styled.div`
  margin: 0 20px 0 ${size.md}px;
`;

const LineItemName = styled.p`
  color: ${colors.gray5};
`;

const LineItemQuantity = styled.aside`
  font-family: ${typography.type.nexa};
  font-size: ${size.sm}px;
  height: 18px;
`;

const LineItemTotal = styled.p`
  text-align: right;
  &.discounted {
    color: ${colors.red3};
  }
`;

const LineItemOriginalAmount = styled.p`
  color: ${colors.gray3};
  text-align: right;
  text-decoration: line-through;
`;

const ImgPlaceholder = styled.div`
  align-items: center;
  background-color: ${colors.gray2};
  color: gray;
  display: flex;
  font-size: 10px;
  height: 46px;
  justify-content: center;
  width: 69px;
`;

const ProductImage = styled.div`
  height: 46px;
  min-width: 69px;
  width: 69px;
`;

const DisclosureTextContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: ${size.lg}px;
  padding: ${size.md}px;
  text-align: 'center';
  background: ${colors.green2};
`;

const AgeRestrictionModalLink = styled.span`
  color: ${colors.teal5};
  cursor: pointer;
`;

const Link = styled.a`
  color: ${colors.blue1};
`;

const TotalsContainer = styled.div`
  margin-top: ${size.sm}px;
  margin-bottom: ${size.xl1}px;
`;

const DiscountAppliedContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${size.sm}px;
`;

const DiscountApplied = styled.div`
  margin-left: ${size.md}px;
  padding: 2px ${size.sm}px;
  border-radius: 1;
  background-color: ${colors.gray1};
  display: flex;
  align-items: center;
`;

type LineItemProps = {
  end?: number;
};
const LineItem = styled.div<LineItemProps>`
  display: flex;
  justify-content: space-between;
  ${(props) =>
    props.end
      ? `padding-bottom: ${size.sm}px; margin-bottom: ${size.sm}px; border-bottom: 1px solid ${colors.green2};`
      : null}
`;

type ItemProps = {
  subTotal?: boolean;
  discountTotal?: boolean;
  grandTotal?: boolean;
};
const Item = styled.span<ItemProps>`
  ${typography.bodyTextSmall}
  ${(props) =>
    props.subTotal ? `font-weight: ${typography.weight.bold};` : null};
  ${(props) =>
    props.discountTotal
      ? `font-weight: ${typography.weight.bold}; color: ${colors.red3};`
      : null};
  ${(props) =>
    props.grandTotal
      ? `${typography.bodyText} font-weight: ${typography.weight.bold};`
      : null};
`;

const Img = styled.img`
  width: ${size.sm}px;
  color: ${colors.green5};
  margin-left: ${size.xs2}px;
`;

const CloseIcon = styled(Icons.Close)`
  width: ${size.md}px;
  margin-left: ${size.sm}px;
  cursor: pointer;
`;

const DiscountText = styled.span`
  font-weight: ${typography.weight.bold};
`;

const ProgressCircleAnimation = styled.div`
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${colors.white};
  opacity: 0.95;
  z-index: 100;

  &::after {
    position: absolute;
    animation: loading 500ms infinite linear;
    border: ${size.xs3}px solid ${colors.green3};
    border-radius: 50%;
    border-right-color: transparent;
    border-top-color: transparent;
    content: '';
    height: ${size.xl1}px;
    width: ${size.xl1}px;
    left: 50%;
    top: 40%;
    margin-left: -0.4rem;
    margin-top: -0.4rem;

    ${mediaQueries.forDesktopDown} {
      top: 45%;
    }
  }
`;

const PromoAndGiftContainer = styled.div`
  margin-top: ${size.sm}px;
`;

const SMSLegalText = styled.div`
  margin-top: ${size.md}px;
`;

const LegalNoticeLinkWrapper = styled.div`
  display: inline-block;
`;

export {
  AgeRestrictionModalLink,
  AltPayments,
  BillingOptions,
  BillingOptionsWrapper,
  CardContent,
  CardLayout,
  CheckboxText,
  CheckoutButton,
  CloseIcon,
  ContentContainer,
  CustomGatsbyImage,
  CustomGatsbyImageWrapper,
  CvcTooltip,
  DisclosureContainer,
  DisclosureTextContainer,
  DiscountApplied,
  DiscountAppliedContainer,
  DiscountText,
  Divider,
  FlexGrowCol,
  FlexOrder,
  GiftCardLink,
  Img,
  ImgPlaceholder,
  InputButton,
  InputButtonBG,
  Item,
  LegalNoticeLinkWrapper,
  LineItem,
  LineItemDetails,
  LineItemDetailsWrapper,
  LineItemName,
  LineItemOriginalAmount,
  LineItemQuantity,
  LineItemTotal,
  LineItemWrapper,
  Link,
  OrderButton,
  OrderButtonWrapper,
  OrderSummaryContainer,
  PaymentMethodItem,
  PaymentMethods,
  PaymentMethodText,
  PaymentMethodTextWrapper,
  PayPalButtonsContainer,
  ProductImage,
  ProgressCircleAnimation,
  PromoAndGiftContainer,
  CheckoutDropdown,
  SMSLegalText,
  StripeInput,
  StripeInputWrapper,
  StripeLabel,
  StyledInputContainer,
  StyledLabel,
  Title,
  TooltipWrapper,
  TotalsContainer,
};
