import { Input } from '@everlywell/leaves';
import React, { FC, SyntheticEvent } from 'react';

import * as S from './styles';

export interface Props {
  buttonText: string;
  disabled?: boolean;
  id: string;
  label?: string;
  name: string;
  onChange?: (e: SyntheticEvent<HTMLInputElement>) => void;
  onClick: (e: SyntheticEvent<HTMLInputElement>) => void;
  placeholder?: string;
  helperText?: string;
  value?: string;
}

export const InputWithButton: FC<Props> = ({
  buttonText,
  disabled,
  id,
  label,
  name,
  onChange,
  onClick,
  placeholder,
  helperText,
  value,
}) => (
  <>
    {label ? <S.StyledLabel htmlFor={id}>{label}</S.StyledLabel> : null}
    <S.StyledInputContainer>
      <Input
        id={id}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
        data-testid={`${name}-input`}
        helperText={helperText}
        value={value}
      />
      <S.InputButtonBG />
      <S.InputButton
        appearance="tertiary"
        // @ts-ignore TODO: Temporary ts-ignore to not block a deploy.
        onClick={onClick}
        isDisabled={disabled}
        data-testid={`${name}-button`}
      >
        {buttonText}
      </S.InputButton>
    </S.StyledInputContainer>
  </>
);

export default InputWithButton;
