import { colors, typography, size } from '@everlywell/leaves';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  background-color: white;
`;

interface NotificationTextProps {
  isError?: boolean;
}
const NotificationText = styled.div<NotificationTextProps>`
  font-weight: ${typography.weight.light};
  font-size: ${size.md}px;
  padding: ${size.xs1}px ${size.xs1}px;
  color: ${(props) => (props.isError ? colors.red2 : colors.white)};
  background-color: ${(props) => (props.isError ? colors.red1 : colors.green4)};
`;

export { Wrapper, NotificationText };
