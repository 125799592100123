import { Row, Col, Input, Checkbox } from '@everlywell/leaves';
import StyledContentfulText from 'components/StyledContentfulText';
import React, { SyntheticEvent, Ref, useState } from 'react';
import { FieldError, useFormContext } from 'react-hook-form';
import { CHECKOUT } from 'utils/constants/dataTest';
import { GetStateAbbreviations } from 'utils/stateAbbreviations';
import { ContentfulHtmlLongText } from 'utils/types';

import { dataLayerCartAbandonment } from './AnalyticsHelpers';
import { formatZipCode, formatPhoneNumber } from './checkoutHelpers';
import * as S from './styles';

interface Props {
  register<T>(validationConfig?: {
    required?: string | boolean;
    pattern?: {
      value: RegExp;
      message: string;
    };
  }): Ref<T> | undefined;
  disableEmail?: boolean;
  disableInput?: boolean;
  isBilling?: boolean;
  sectionNumber?: number;
  sectionTitle?: string;
  errors: {
    [key: string]: FieldError;
  };
  includeNewYork: boolean;
  stateDisclaimer: string;
  smsOptInCheckboxText?: ContentfulHtmlLongText;
  phoneHelperText?: string;
}

const ShippingInfo: React.FC<Props> = ({
  disableEmail = false,
  disableInput = false,
  isBilling = false,
  register,
  errors = {},
  sectionNumber,
  sectionTitle,
  includeNewYork,
  stateDisclaimer,
  smsOptInCheckboxText,
  phoneHelperText,
}: Props) => {
  const formType = isBilling ? 'bill' : 'ship';
  const { trigger, getValues, setValue } = useFormContext();
  const states = GetStateAbbreviations({ includeNewYork });
  const [statesDropdownClass, setStatesDropdownClass] =
    useState<string>('state-default');
  const [isSmsOptInChecked, setIsSmsOptInChecked] = useState(false);

  const format = (e: SyntheticEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    if (target.name === `${formType}Zipcode`) {
      target.value = formatZipCode(target.value);
    }
    if (target.name === `${formType}Phone`) {
      target.value = formatPhoneNumber(target.value);
    }
  };

  const handleEmailBlur = () => {
    trigger('email').then((isValid) => {
      if (isValid) {
        const email = getValues('email');
        dataLayerCartAbandonment(email);
      }
    });
  };

  const getSmsOptInCheckboxContent = (): JSX.Element | null =>
    smsOptInCheckboxText ? (
      <StyledContentfulText text={smsOptInCheckboxText}></StyledContentfulText>
    ) : null;

  return (
    <div data-testid={`${formType}Information`}>
      <S.ContentContainer useDifferentBillingAddress={isBilling}>
        {!isBilling ? (
          <Row>
            <Col xs>
              <S.Title>
                {sectionNumber}. {sectionTitle}
              </S.Title>
            </Col>
          </Row>
        ) : null}
        <Row>
          <Col>
            <div
              data-testid={
                !isBilling
                  ? CHECKOUT.SHIPPING_ADDRESS_FORM
                  : CHECKOUT.BILLING_ADDRESS_FORM
              }
            >
              {!isBilling ? (
                <Input
                  id="email"
                  data-testid={CHECKOUT.EMAIL_CHECKOUT_INPUT}
                  label="Email"
                  name="email"
                  type="email"
                  placeholder="Jane@email.com"
                  error={errors.email && errors.email.message}
                  ref={register({
                    required: 'Please enter an email address',
                    pattern: {
                      value:
                        // eslint-disable-next-line no-control-regex
                        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i,
                      message: 'Please enter a valid email address',
                    },
                  })}
                  disabled={disableInput}
                  // To keep parity with current checkout - one of two fields that validate initially onBlur - others validate initially onSubmit
                  onBlur={handleEmailBlur}
                  readOnly={disableEmail}
                />
              ) : null}

              <Row>
                <Col xs md>
                  <Input
                    id={`${formType}FirstName`}
                    data-testid={CHECKOUT.FIRST_NAME_CHECKOUT_INPUT}
                    name={`${formType}FirstName`}
                    label="First Name"
                    placeholder="Jane"
                    error={
                      errors[`${formType}FirstName`] &&
                      errors[`${formType}FirstName`].message
                    }
                    ref={register({
                      required: 'Please enter a first name',
                    })}
                    disabled={disableInput}
                    onBlur={() => trigger(`${formType}FirstName`)}
                  />
                </Col>
                <Col xs md>
                  <Input
                    id={`${formType}LastName`}
                    data-testid={CHECKOUT.LAST_NAME_CHECKOUT_INPUT}
                    name={`${formType}LastName`}
                    label="Last Name"
                    placeholder="Doe"
                    error={
                      errors[`${formType}LastName`] &&
                      errors[`${formType}LastName`].message
                    }
                    ref={register({
                      required: 'Please enter a last name',
                    })}
                    disabled={disableInput}
                    onBlur={() => trigger(`${formType}LastName`)}
                  />
                </Col>
              </Row>
              <Input
                id={`${formType}Address1`}
                data-testid={CHECKOUT.ADDRESS_ONE_CHECKOUT_INPUT}
                name={`${formType}Address1`}
                label="Address 1"
                placeholder="1234 Apple St"
                error={
                  errors[`${formType}Address1`] &&
                  errors[`${formType}Address1`].message
                }
                ref={register({
                  required: 'Please enter an address',
                })}
                disabled={disableInput}
                onBlur={() => {
                  trigger(`${formType}Address1`);
                }}
              />
              <Input
                id={`${formType}Address2`}
                data-testid={CHECKOUT.ADDRESS_TWO_CHECKOUT_INPUT}
                name={`${formType}Address2`}
                label="Address 2 (Optional)"
                placeholder="Apartment, suite, etc"
                ref={register()}
                disabled={disableInput}
                onFocus={() => {
                  // Whenever the address2 is focused, It means all the other address fields are already filled up
                  setValue(
                    `${formType}Address1`,
                    (
                      document.querySelector(
                        `#shipAddress1`,
                      ) as HTMLInputElement
                    ).value,
                  );
                  setValue(
                    `${formType}Zipcode`,
                    (document.querySelector(`#shipZipcode`) as HTMLInputElement)
                      .value,
                  );
                  setValue(
                    `${formType}City`,
                    (document.querySelector(`#shipCity`) as HTMLInputElement)
                      .value,
                  );
                  setValue(
                    `${formType}State`,
                    (document.querySelector(`#shipState`) as HTMLInputElement)
                      .value,
                  );
                }}
              />
              <Row>
                <Col>
                  <Input
                    id={`${formType}City`}
                    data-testid={CHECKOUT.CITY_CHECKOUT_INPUT}
                    name={`${formType}City`}
                    label="City"
                    placeholder="Austin"
                    error={
                      errors[`${formType}City`] &&
                      errors[`${formType}City`].message
                    }
                    ref={register({
                      required: 'Please enter a city',
                    })}
                    disabled={disableInput}
                    onBlur={() => trigger(`${formType}City`)}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <S.CheckoutDropdown
                    className={statesDropdownClass}
                    onChange={() => setStatesDropdownClass('state-selected')}
                    items={states}
                    data-testid={CHECKOUT.STATE_CHECKOUT_INPUT}
                    label="State"
                    name={`${formType}State`}
                    id={`${formType}State`}
                    placeholderText="State"
                    showErrorMessage
                    error={
                      errors[`${formType}State`] &&
                      errors[`${formType}State`].message
                    }
                    ref={register({
                      required: 'Please select a state',
                    })}
                    disabled={disableInput}
                    onBlur={() => trigger(`${formType}State`)}
                    helperText={stateDisclaimer}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs md>
                  <Input
                    id={`${formType}Zipcode`}
                    data-testid={CHECKOUT.ZIP_CODE_CHECKOUT_INPUT}
                    name={`${formType}Zipcode`}
                    label="ZIP Code"
                    placeholder="00000"
                    onChange={format}
                    inputMode="numeric"
                    type="tel"
                    error={
                      errors[`${formType}Zipcode`] &&
                      errors[`${formType}Zipcode`].message
                    }
                    ref={register({
                      required: 'Please enter a ZIP code',
                      pattern: {
                        value: /(^\d{5}(-\d{4})?$)/,
                        message: 'Please enter a valid ZIP code',
                      },
                    })}
                    disabled={disableInput}
                    onBlur={() => trigger(`${formType}Zipcode`)}
                  />
                </Col>
                <S.FlexGrowCol xs md>
                  <Input
                    id={`${formType}Phone`}
                    data-testid={CHECKOUT.PHONE_NUMBER_CHECKOUT_INPUT}
                    name={`${formType}Phone`}
                    label="Phone Number (Optional)"
                    placeholder="(555) 555-5555"
                    onChange={format}
                    inputMode="numeric"
                    type="tel"
                    error={
                      errors[`${formType}Phone`] &&
                      errors[`${formType}Phone`].message
                    }
                    disabled={disableInput}
                    ref={register({
                      pattern: {
                        value: /^\(?[2-9]\d{2}\)?[- .]?\d{3}[- .]?\d{4}$/,
                        message: 'Please enter a valid phone number',
                      },
                    })}
                    helperText={phoneHelperText}
                  />
                </S.FlexGrowCol>
              </Row>
              {smsOptInCheckboxText ? (
                <Row>
                  <Col>
                    <Checkbox
                      checked={isSmsOptInChecked}
                      name="smsOptIn"
                      ref={register()}
                      onChange={() => {
                        setIsSmsOptInChecked(!isSmsOptInChecked);
                        setValue('smsOptIn', !isSmsOptInChecked);
                      }}
                      label={getSmsOptInCheckboxContent()}
                      uid={CHECKOUT.SMS_OPT_IN_CHECKBOX}
                      border={false}
                      bodyTextStyle
                    />
                  </Col>
                </Row>
              ) : null}
            </div>
          </Col>
        </Row>
      </S.ContentContainer>
    </div>
  );
};

export default ShippingInfo;
