import { Button, H3, Input } from '@everlywell/leaves';
import {
  formatZipCode,
  getNearestLabs,
  hasSelectedPscLocation,
} from 'components/Checkout/checkoutHelpers';
import LoadingAnimation from 'components/Checkout/LoadingAnimation';
import { PSCLocation } from 'components/Checkout/types';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, {
  SyntheticEvent,
  useState,
  Dispatch,
  SetStateAction,
  Ref,
} from 'react';
import { FieldError } from 'react-hook-form';
import ewAnalytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';
import { CHECKOUT } from 'utils/constants/dataTest';
import { logError } from 'utils/helpers';

import PscList from '../PscList/index';
import * as S from '../styles';

export interface Props {
  setSelectedPsc: Dispatch<SetStateAction<PSCLocation>>;
  selectedPsc: PSCLocation;
  register: (
    params: Record<string, string>,
  ) => Ref<HTMLInputElement> | undefined;
  errors: {
    [key: string]: FieldError;
  };
}

const LabSearch = ({
  setSelectedPsc,
  selectedPsc,
  register,
  errors = {},
}: Props): JSX.Element => {
  const contentfulAssets = useStaticQuery(graphql`
    {
      emptyPSCStateIcon: contentfulAsset(title: { eq: "Empty State Icon" }) {
        title
        gatsbyImageData(placeholder: BLURRED)
        file {
          url
        }
      }
      noLocations: contentfulAsset(title: { eq: "No Locations Icon" }) {
        title
        gatsbyImageData(placeholder: BLURRED)
        file {
          url
        }
      }
    }
  `);

  const noLocationsCopy =
    'No Quest Diagonostics labs were found within 250 miles of your location. Please try another ZIP Code or explore our at-home lab tests.';
  const emptyPSCStateCopy =
    'Enter your ZIP code to find a lab near you (or the person taking this test)';
  const issueWithNearlestLabs =
    'There was an issue with requesting the nearest labs. Please try again.';

  // default value ("No results found")
  let resultsText = noLocationsCopy;

  const emptyPSCStateIcon =
    contentfulAssets?.emptyPSCStateIcon?.gatsbyImageData;
  const noLocationsIcon = contentfulAssets?.noLocations?.gatsbyImageData;
  const [pscZip, setPscZip] = useState('');
  const [noResultsText, setNoResultsText] = useState(emptyPSCStateCopy);
  const [iconToDisplay, setIconToDisplay] = useState(emptyPSCStateIcon);
  const [pscLocations, setPscLocations] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleOnChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    target.value = formatZipCode(target.value);
    setPscZip(target.value);
  };

  const handleSearchClick = async (e: SyntheticEvent) => {
    e.preventDefault();
    // Turn on loading state
    setIsSubmitting(true);

    // Handle Analytics tracking
    ewAnalytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: ANALYTICS.LABELS.LAB_SEARCH_BY_ZIPCODE,
      },
    });

    let locations;
    // Make API call to get nearest lab locations
    try {
      locations = await getNearestLabs(pscZip);
    } catch (error) {
      logError('There was an issue requesting the nearest labs', {
        component: 'LabSearch',
        function: 'handleSearchClick',
        stackTrace: error,
      });
      resultsText = issueWithNearlestLabs;
    }

    // Check if we get any results back
    if (!locations || locations?.data.length === 0) {
      // Reset PSC locations data
      setPscLocations([]);
      // Display the "No locations found" icon with copy
      setIconToDisplay(noLocationsIcon);
      setNoResultsText(resultsText);
      setIsSubmitting(false);
      return;
    }

    // Set the list of PSCs to state
    setPscLocations(locations.data);
    // Turn off loading state
    setIsSubmitting(false);
  };

  let displayResults = <div />;
  const hasPscLocations = pscLocations.length > 0;

  if (!hasPscLocations) {
    displayResults = (
      <S.PscResultsContainer
        data-testid={CHECKOUT.PSC_RESULTS_CONTAINER_EMPTY_STATE}
      >
        <p>{noResultsText}</p>

        <GatsbyImage image={iconToDisplay} alt="empty-state" />
      </S.PscResultsContainer>
    );
  } else {
    displayResults = (
      <PscList
        pscLocations={pscLocations}
        setSelectedPsc={setSelectedPsc}
        selectedPsc={selectedPsc}
      />
    );
  }

  return (
    <>
      <H3>Search for a Quest lab</H3>
      <Input
        data-testid={CHECKOUT.SEARCH_BY_ZIPCODE_INPUT}
        id="nearby-psc-zip"
        name="nearby-psc-zip"
        placeholder="00000"
        label="Zip Code"
        onChange={handleOnChange}
        error={errors['nearby-psc-zip'] && errors['nearby-psc-zip'].message}
        ref={register({
          required: 'Please enter a ZIP Code and select a lab',
        })}
      />
      <Button
        data-testid={CHECKOUT.SEARCH_BY_ZIPCODE_BUTTON}
        appearance="primary"
        onClick={handleSearchClick}
        isDisabled={pscZip.length < 5}
      >
        Search
      </Button>
      <LoadingAnimation isSubmitting={isSubmitting} />
      {displayResults}

      <H3>Your suggested lab:</H3>

      <p>
        <span>Please note:</span>
        <br />
        This does not mean you have an appointment or that you have to make an
        appointment at this lab. This is only a suggested location based off the
        zip code you entered above.
      </p>
      <br />
      <span>Address</span>
      {/* SELECTED LAB */}
      {hasSelectedPscLocation(selectedPsc) ? (
        <S.PscDetailsContainer>
          <S.LocationDetailsText>{selectedPsc.name}</S.LocationDetailsText>
          <S.LocationDetailsText>{selectedPsc.address}</S.LocationDetailsText>
          <S.LocationDetailsText>
            {selectedPsc.city}, {selectedPsc.state} {selectedPsc.zip}
          </S.LocationDetailsText>
        </S.PscDetailsContainer>
      ) : (
        <p>No lab selected yet</p>
      )}
    </>
  );
};

export default LabSearch;
