import React from 'react';

import * as S from './styles';

export const NOTIFICATION_CONT_TEST_ID = 'toast-notification';

type Props = {
  isError?: boolean;
  children: React.ReactNode;
};

export const Notification: React.FC<Props> = ({ isError, children }: Props) => (
  <S.Wrapper data-testid={NOTIFICATION_CONT_TEST_ID}>
    <S.NotificationText isError={isError}>{children}</S.NotificationText>
  </S.Wrapper>
);

export default Notification;
