import { PSCLocation } from 'components/Checkout/types';
import React from 'react';
import ewAnalytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';
import { CHECKOUT } from 'utils/constants/dataTest';

import * as S from '../styles';

type Props = {
  pscLocations: PSCLocation[];
  setSelectedPsc: Function;
  selectedPsc: PSCLocation;
};

const PscList = ({
  pscLocations,
  setSelectedPsc,
  selectedPsc,
}: Props): JSX.Element => {
  const roundDistance = (distance: number) =>
    Math.round((distance + Number.EPSILON) * 100) / 100;

  const handlePscClick = async (psc: PSCLocation) => {
    ewAnalytics.track({
      event: ANALYTICS.EVENTS.CLICKED_PSC_LOCATION,
      data: {
        label: ANALYTICS.LABELS.SELECT_LAB_LOCATION,
      },
    });
    setSelectedPsc(psc);
  };
  return (
    <>
      <S.LocationsFound>{pscLocations.length} locations found</S.LocationsFound>
      <S.PscListContainer
        data-testid={CHECKOUT.PSC_RESULTS_CONTAINER_WITH_RESULTS}
      >
        {pscLocations.map((location: PSCLocation, idx) => {
          const locationCount = idx + 1;
          const hasSelectedPsc = selectedPsc === location;
          return (
            <S.PscLocationContainer
              key={locationCount}
              hasSelectedPsc={hasSelectedPsc}
              onClick={() => handlePscClick(location)}
              data-testid={CHECKOUT.PSC_LOCATION_RESULTS}
            >
              <S.PscLocationNumberContainer>
                <S.PscLocationNumber
                  hasSelectedPsc={hasSelectedPsc}
                  data-testid={CHECKOUT.LOCATION_NUMBER}
                >
                  {locationCount}
                </S.PscLocationNumber>
              </S.PscLocationNumberContainer>
              <S.PscDetailsContainer>
                <S.NameAndDistance>
                  <p data-testid={CHECKOUT.LOCATION_NAME}>{location.name}</p>
                  <p
                    className="distance-text"
                    data-testid={CHECKOUT.LOCATION_DISTANCE}
                  >
                    {roundDistance(location.distance)} miles
                  </p>
                </S.NameAndDistance>
                <S.LabAddressContainer>
                  <S.LocationDetailsText
                    data-testid={CHECKOUT.LOCATION_ADDRESS}
                  >
                    {location.address}
                  </S.LocationDetailsText>
                  <S.LocationDetailsText
                    data-testid={CHECKOUT.LOCATION_CITY_STATE_ZIP}
                  >
                    {location.city}, {location.state} {location.zip}
                  </S.LocationDetailsText>
                  <S.LocationHoursText data-testid={CHECKOUT.LOCATION_HOURS}>
                    {location.hours}
                  </S.LocationHoursText>
                </S.LabAddressContainer>
              </S.PscDetailsContainer>
            </S.PscLocationContainer>
          );
        })}
      </S.PscListContainer>
    </>
  );
};

export default PscList;
