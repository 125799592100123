import {
  useHooks,
  SkeletonProps,
  SkeletonUnit,
  SkeletonLoader,
} from '@everlywell/leaves';
import { Order } from 'components/Checkout/types';
import React, { SyntheticEvent, useEffect, useContext, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { CART_EVENTS_NAMES, getShippingMethodId } from 'utils/cartHelpers';
import { CHECKOUT } from 'utils/constants/dataTest';
import { SHIPPING } from 'utils/constants/shipping';
import { formatPrice } from 'utils/helpers';

import plusIcon from '../../images/icons/icon-plus.svg';
import { displayLineItemsQty } from './checkoutHelpers';
import { salesTaxQuotation } from './checkoutHelpers';
import InputWithButton from './InputWithButton';
import { PlaceOrderButton } from './PlaceOrderButton';
import { useLDClient } from 'gatsby-plugin-launchdarkly';
import { PlainFlags, Flags } from 'utils/constants/featureFlags';
import * as S from './styles';

type handleApplyPromoClickFunction = {
  (event: SyntheticEvent<HTMLInputElement>, promoValue: string): void;
};

type handleGiftCardApplyFunction = {
  (event: SyntheticEvent<HTMLInputElement>, giftCard: string): void;
};

type handleGiftCardRemoveFunction = {
  (adjustmentId: number): void;
};

export type Props = {
  order: Order | undefined;
  lineItems: JSX.Element[] | undefined;
  isSubmitting: boolean;
  handleApplyPromoClick: handleApplyPromoClickFunction;
  handleGiftCardApply: handleGiftCardApplyFunction;
  removeDiscount: () => void;
  removeGiftCard: handleGiftCardRemoveFunction;
  isApplyingPromo: boolean;
  hideShippingInfo?: boolean;
};

const OrderInfo = ({
  order,
  isSubmitting,
  lineItems,
  handleApplyPromoClick,
  handleGiftCardApply,
  removeDiscount,
  removeGiftCard,
  isApplyingPromo,
  hideShippingInfo,
}: Props): JSX.Element => {
  const subTotal = parseFloat(order?.amount || '');
  const shippingPrice = parseFloat(order?.shipping || '');
  const promoTotal = parseFloat(order?.promo_total || '0');
  const giftCardTotal = parseFloat(order?.gift_card_total || '');
  // If the backend sends any shipping amount, it will be deducted from the total
  const total = parseFloat(order?.total || '') - shippingPrice;

  const discountTotal = Math.abs(promoTotal + giftCardTotal);

  const showDiscount = discountTotal > 0;

  const initialShippingMethod = getShippingMethodId();
  const [showGiftCard, setShowGiftCard] = useState(false);
  const [promoValue, setPromoValue] = useState(
    order?.promotions[0]?.code || '',
  );
  const [giftCardValue, setGiftCardValue] = useState('');
  const { isMobile } = useHooks.useWindowSize();
  const [shippingMethod, setShippingMethod] = useState(initialShippingMethod);
  const [hasPromoApplied, setHasPromoApplied] = useState<boolean>(!!promoTotal);

  const renderShippingDisplay = () => {
    if (shippingMethod === SHIPPING.EXPRESS) {
      return `+$${formatPrice(SHIPPING.EXPRESS_FEE)}`;
    }
    if (shippingPrice > 0) {
      return `$${formatPrice(shippingPrice)}`;
    }
    return 'FREE';
  };

  const renderTotalDisplay = () => {
    const grandTotal = quotationFeatureFlag ? total + salesTaxTotal : total;

    return shippingMethod === SHIPPING.EXPRESS
      ? `$${formatPrice(grandTotal + SHIPPING.EXPRESS_FEE)}`
      : `$${formatPrice(grandTotal)}`;
  };

  useEffect(() => {
    window.addEventListener(CART_EVENTS_NAMES.setShippingMethod, () => {
      const currentShippingMethod = getShippingMethodId();
      setShippingMethod(currentShippingMethod);
    });
  }, []);

  useEffect(() => {
    if (promoTotal !== 0) {
      setHasPromoApplied(true);
    } else {
      setHasPromoApplied(false);
    }
  }, [promoTotal]);

  useEffect(() => {
    if (order?.promotions[0]?.code) {
      setPromoValue(order.promotions[0].code);
    } else {
      setPromoValue('');
    }
  }, [order?.promotions]);

  const placeholderConfig: SkeletonProps = {
    height: {
      value: 62,
      unit: SkeletonUnit.Pixel,
    },
    width: {
      value: 100,
      unit: SkeletonUnit.Percentage,
    },
  };

  const { watch } = useFormContext();

  const [calculatingSalesTax, setCalculatingSalesTax] = useState(true);

  const watchEmail = watch('email', '');
  const watchAddress1 = watch('shipAddress1', '');
  const watchZip = watch('shipZipcode', '');
  const watchCity = watch('shipCity', '');
  const watchState = watch('shipState', '');

  const [salesTaxTotal, setSalesTaxTotal] = useState(0);
  const [canRequest, setCanRequest] = useState(false);

  const [quotationFeatureFlag, setQuotationFeatureFlag] = useState(false);
  const [hadPromoApplied, setHadPromoApplied] = useState(false);

  function isAllSalesTaxFieldsFilled() {
    return (
      watchAddress1.length !== 0 &&
      watchZip.length !== 0 &&
      watchCity.length !== 0 &&
      watchState.length !== 0 &&
      (watchZip.length === 5 || watchZip.length === 10)
    );
  }

  useEffect(() => {
    if (hasPromoApplied) {
      setHadPromoApplied(true); // To avoid calling collectSalesTaxQuotation on component mount
    }
  }, [hasPromoApplied]);

  // Trigger to collect sales tax when applying/removing promo code
  useEffect(() => {
    if (hadPromoApplied && isAllSalesTaxFieldsFilled()) {
      collectSalesTaxQuotation();
    }
  }, [hasPromoApplied, hadPromoApplied]);

  useEffect(() => {
    if (isAllSalesTaxFieldsFilled() && quotationFeatureFlag) {
      setCanRequest(true);
    } else {
      setCanRequest(false);
      setSalesTaxTotal(0);
    }
  }, [watchAddress1, watchZip, watchCity, watchState, isAllSalesTaxFieldsFilled]);

  useEffect(() => {
    setCalculatingSalesTax(true);
  }, [watchAddress1, watchZip, watchCity, watchState]);

  const ldClient = useLDClient();

  ldClient
    ?.identify({ key: Flags.SalesTaxQuotation, email: watchEmail })
    .then((flags) => {
      setQuotationFeatureFlag(flags[PlainFlags.SalesTaxQuotation]);
    });

  const callSalesTaxEndpoint = async () =>
    salesTaxQuotation(
      order?.id,
      {
        address1: watchAddress1,
        city: watchCity,
        state: watchState,
        zipcode: watchZip,
      },
      watchEmail,
    );

  const collectSalesTaxQuotation = async () => {
    try {
      const response = await callSalesTaxEndpoint();
      setCalculatingSalesTax(false);
      setSalesTaxTotal(response.data['total_tax']);
    } catch {
      alert('Failed to get sales tax quotation');
    }
  };

  // Set sales tax whenever the addresses are filled
  useEffect(() => {
    const delayQuoteApiRequest = setTimeout(() => {
      if (canRequest) {
        collectSalesTaxQuotation();
      }
    }, 3000);

    return () => clearTimeout(delayQuoteApiRequest);
  }, [watchAddress1, watchZip, watchCity, watchState, canRequest]);

  return (
    <S.CardLayout>
      {lineItems ? (
        lineItems
      ) : (
        <SkeletonLoader
          testId={CHECKOUT.LINE_ITEMS_PLACEHOLDER}
          height={placeholderConfig.height}
          width={placeholderConfig.width}
        />
      )}
      <S.PromoAndGiftContainer>
        <InputWithButton
          buttonText="Apply"
          disabled={isSubmitting || isApplyingPromo || hasPromoApplied}
          id="promo"
          label="Promo codes must be used at time of purchase."
          name="promo"
          onChange={(e: SyntheticEvent<HTMLInputElement>) => {
            const target = e.target as HTMLInputElement;
            setPromoValue(target.value);
          }}
          onClick={(e) => handleApplyPromoClick(e, promoValue)}
          placeholder="Enter promo code"
          helperText={
            hasPromoApplied ? 'Max 1 promo code per order' : undefined
          }
          value={promoValue}
        />
        <S.GiftCardLink
          onClick={() => setShowGiftCard(!showGiftCard)}
          data-testid="show-gift-card-input"
        >
          Pay with a gift card <S.Img src={plusIcon} alt="plus icon" />
        </S.GiftCardLink>
        {showGiftCard ? (
          <InputWithButton
            buttonText="Apply"
            disabled={isSubmitting}
            id="gift-card"
            name="gift-card"
            placeholder="Enter gift card number"
            onChange={(e: SyntheticEvent<HTMLInputElement>) => {
              const target = e.target as HTMLInputElement;
              setGiftCardValue(target.value);
            }}
            onClick={(e) => handleGiftCardApply(e, giftCardValue)}
          />
        ) : null}
      </S.PromoAndGiftContainer>

      {hasPromoApplied && promoValue ? (
        <S.DiscountAppliedContainer>
          <S.Item data-testid={CHECKOUT.DISCOUNT_APPLIED_LABEL}>Applied</S.Item>
          <S.Item>
            <S.DiscountApplied>
              <S.DiscountText data-testid={CHECKOUT.DISCOUNT_APPLIED}>
                {promoValue}
              </S.DiscountText>{' '}
              <S.CloseIcon onClick={removeDiscount} />
            </S.DiscountApplied>
          </S.Item>
        </S.DiscountAppliedContainer>
      ) : null}

      {order?.gift_cards?.map((giftCard) => (
        <S.DiscountAppliedContainer key={giftCard.gift_card_code}>
          <S.Item data-testid={CHECKOUT.GIFT_CARD_APPLIED_LABEL}>
            Applied
          </S.Item>
          <S.Item>
            <S.DiscountApplied>
              <S.DiscountText data-testid={CHECKOUT.GIFT_CARD_APPLIED}>
                {giftCard.gift_card_code}
              </S.DiscountText>{' '}
              <S.CloseIcon
                onClick={() => removeGiftCard(giftCard.adjustment_id)}
              />
            </S.DiscountApplied>
          </S.Item>
        </S.DiscountAppliedContainer>
      ))}

      <S.TotalsContainer data-testid={CHECKOUT.ORDER_TOTALS}>
        <S.LineItem end={hideShippingInfo && !showDiscount ? 1 : 0}>
          <S.Item data-testid={CHECKOUT.SUB_TOTAL_LABEL}>
            Subtotal ({displayLineItemsQty(order?.line_items)}
            ):
          </S.Item>
          <S.Item data-testid={CHECKOUT.SUB_TOTAL} subTotal>
            ${formatPrice(subTotal)}
          </S.Item>
        </S.LineItem>
        {hideShippingInfo ? null : (
          <S.LineItem end={!showDiscount ? 1 : 0}>
            <S.Item>Shipping:</S.Item>
            <S.Item data-testid={CHECKOUT.SHIPPING}>
              {renderShippingDisplay()}
            </S.Item>
          </S.LineItem>
        )}
        {showDiscount ? (
          <S.LineItem end={1}>
            <S.Item>Discount:</S.Item>
            <S.Item data-testid={CHECKOUT.DISCOUNT_TOTAL} discountTotal>
              -${formatPrice(discountTotal)}
            </S.Item>
          </S.LineItem>
        ) : null}

        {quotationFeatureFlag && (
          <S.LineItem end={!showDiscount ? 1 : 0}>
            <S.Item>Estimated Sales Tax:</S.Item>
            <S.Item data-testid={CHECKOUT.ESTIMATED_SALES_TAX}>
              {calculatingSalesTax ? 'TBD' : `$${formatPrice(salesTaxTotal)}`}
            </S.Item>
          </S.LineItem>
        )}

        <S.LineItem>
          <S.Item grandTotal>Grand Total:</S.Item>
          <S.Item data-testid={CHECKOUT.GRAND_TOTAL} grandTotal>
            {renderTotalDisplay()}
          </S.Item>
        </S.LineItem>
      </S.TotalsContainer>
      {!isMobile ? (
        <PlaceOrderButton
          isSubmitting={isSubmitting}
          isLoading={isApplyingPromo}
        />
      ) : null}
    </S.CardLayout>
  );
};

export default OrderInfo;
