import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React, { useRef } from 'react';
import { CHECKOUT } from 'utils/constants/dataTest';
import { formatPrice } from 'utils/helpers';
import { useCartImage } from 'utils/hooks';

import { originalPrice } from './checkoutHelpers';
import * as S from './styles';
import { LineItem } from './types';

interface Props {
  lineItem: LineItem;
}

const OrderLineItem: React.FC<Props> = ({ lineItem }: Props) => {
  const productImage = useRef<IGatsbyImageData>();
  productImage.current = useCartImage(lineItem);

  const basePrice = originalPrice(lineItem);
  const actualPrice = parseFloat(lineItem.total) / lineItem.quantity;
  const discounted = actualPrice !== basePrice;
  const showBasePrice = discounted || lineItem.is_subscription;

  return (
    <S.LineItemWrapper data-testid={CHECKOUT.LINE_ITEM}>
      <S.ProductImage>
        {productImage && productImage.current ? (
          <GatsbyImage
            image={productImage.current}
            alt={`${lineItem.product_name} box`}
          />
        ) : (
          <S.ImgPlaceholder>Image Not Found</S.ImgPlaceholder>
        )}
      </S.ProductImage>
      <S.LineItemDetailsWrapper>
        <S.LineItemDetails>
          <S.LineItemName data-testid={CHECKOUT.LINE_ITEM_NAME}>
            {lineItem.product_name}
          </S.LineItemName>
          <S.LineItemQuantity data-testid={CHECKOUT.LINE_ITEM_QUANTITY}>
            Quantity: {lineItem.quantity}
          </S.LineItemQuantity>
        </S.LineItemDetails>
        <div>
          {showBasePrice && basePrice ? (
            <S.LineItemOriginalAmount
              data-testid={CHECKOUT.LINE_ITEM_ORIGINAL_AMOUNT}
            >
              ${formatPrice(basePrice)}
            </S.LineItemOriginalAmount>
          ) : null}
          <S.LineItemTotal
            data-testid={CHECKOUT.LINE_ITEM_TOTAL}
            className={
              discounted || lineItem.is_subscription ? 'discounted' : ''
            }
          >
            ${formatPrice(actualPrice)}
            {lineItem.is_subscription
              ? `/${lineItem.subscription_frequency}`
              : null}
          </S.LineItemTotal>
        </div>
      </S.LineItemDetailsWrapper>
    </S.LineItemWrapper>
  );
};

export default OrderLineItem;
