import { PaymentDetails, PaymentMethod } from 'components/Checkout/types';
import React from 'react';
import { FC, createContext, useState, useContext } from 'react';

const CheckoutContext = createContext<any>(null);

const CheckoutProvider: FC = ({ children }) => {
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(
    PaymentMethod.Stripe,
  );
  const [paymentDetails, setPaymentDetails] = useState<PaymentDetails>();

  return (
    <CheckoutContext.Provider
      value={{
        paymentMethod,
        setPaymentMethod,
        paymentDetails,
        setPaymentDetails,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};

const useCheckoutContext = () => {
  const context = React.useContext(CheckoutContext);

  if (context === undefined) {
    throw new Error('useCheckout must be used within a CheckoutProvider');
  } else {
    return context;
  }
};

export { CheckoutProvider, useCheckoutContext };
