import {
  Amount,
  PurchaseItem,
  PurchaseUnit,
  CaptureOrderResponseBody,
} from '@paypal/paypal-js/types/apis/orders';
import { sum } from 'lodash-es';

import { formatAbbreviationToState, formatZipCode } from './checkoutHelpers';
import {
  LineItem,
  Order,
  PaymentMethod,
  PayPalPaymentDetails,
  PayPalSubscriptionPaymentDetails,
  PayPalSubscriptionDetails,
} from './types';

/**
 * Helper function for refering to USD-dollars
 *
 * @param value
 * @param currency_code
 * @returns an Amount object for use in the PayPal API
 */
export const USD = (value: string | number, currency_code = 'USD'): Amount => ({
  value: `${(+value).toFixed(2)}`,
  currency_code,
});

export const Zero = '0.00';
export const Free = '0.00';

/**
 * TODO: For us to itemize the purchase, I think we need some backend
 * changes to include this data again when we update the order.
 * Formats serialized Spree::LineItem as PurchaseItem for PayPal.
 * Written to be applied with `.map()`
 *
 * @param lineItem a single line item
 * @returns PurchaseItem: a formatted PurchaseItem
 */
export const formatLineItems = (lineItem: LineItem): PurchaseItem => ({
  name: lineItem.product_name,
  quantity: `${lineItem.quantity}`,
  unit_amount: USD(lineItem.amount),
  sku: `${lineItem.variant_sku}`,
  category: 'PHYSICAL_GOODS',
  tax: USD(Zero),
  description: '',
});

/**
 * Formats serialized Spree::Order as a PurchaseUnit for PayPal.
 * We only allow single PUs, but PayPal expects an array, so
 * we return an array with a single PU in it.
 *
 * @param order the current order
 * @returns a paypal PurchaseItem, which is like an order chunk
 */
export const purchaseUnitFromOrder = (
  order: Order | undefined,
): PurchaseUnit[] => {
  if (!order) {
    return [];
  }

  const {
    orderTotal,
    item_total,
    tax_total,
    discount,
    shipping,
    handling,
    insurance,
    shipping_discount,
  } = calculatePaypalOrderTotals(order);

  return [
    {
      amount: {
        ...USD(orderTotal),
        breakdown: {
          item_total: USD(item_total),
          tax_total: USD(tax_total),
          discount: USD(discount),
          shipping: USD(shipping),
          handling: USD(handling),
          insurance: USD(insurance),
          shipping_discount: USD(shipping_discount),
        },
      },
      custom_id: `${order.id}`,
      // TODO: we can't sumbit these details with our current backend
      // Implementation.
      // items: order.line_items.map(formatLineItems),
    },
  ];
};

/**
 * Function processes Order prices (totals, tax, promos, etc.) and sums
 * them as PayPal defines them, also providing proper formatting
 *
 * @param order : the Spree::Order;
 * @returns order amounts for key items formatted for PayPal
 */
export const calculatePaypalOrderTotals = (order: Order) => {
  const item_total = +order.amount;
  const tax_total = 0;
  // Spree returns `discount` as a negative number and we
  // want it that way so we can use `sum` here. When we return the value,
  // we need to format it as a posative number for PayPal.
  const discount = +order.promo_total;
  const shipping = +order.shipping;
  const handling = 0;
  const insurance = 0;
  const shipping_discount = 0; // This should be negative when not Zero, but returned posative for PayPal
  const orderTotal = sum([
    item_total,
    tax_total,
    discount,
    shipping,
    handling,
    insurance,
    shipping_discount,
  ]);
  return {
    orderTotal,
    item_total,
    tax_total,
    discount: -discount, // See note above
    shipping,
    handling,
    insurance,
    shipping_discount: -shipping_discount,
  };
};

/**
 * Update the form data based on the PayPal Order API response
 *
 * @param data The response body from PayPal on successful order creation.
 * @returns void
 */
export const updateFormData = async (
  data: any,
  setValue: Function,
  trigger: Function,
): Promise<void> => {
  try {
    // Payer Data
    const email = data?.payer?.email_address || '';
    const firstName = data?.payer?.name?.given_name || '';
    const lastName = data?.payer?.name?.surname || '';
    setValue('email', email);
    setValue('shipFirstName', firstName);
    setValue('shipLastName', lastName);

    // Shipping Address (we don't bother with billing)
    const ship_address = {
      country: data?.payer?.address?.country_code || '',
      state: data?.purchase_units[0]?.shipping?.address?.admin_area_1 || '',
      city: data?.purchase_units[0]?.shipping?.address?.admin_area_2 || '',
      street1: data?.purchase_units[0]?.shipping?.address?.address_line_1 || '',
      street2: data?.purchase_units[0]?.shipping?.address?.address_line_2 || '',
      zipcode: data?.purchase_units[0]?.shipping?.address?.postal_code || '',
    };

    setValue('shipState', formatAbbreviationToState(ship_address.state));
    setValue('shipCity', ship_address.city);
    setValue('shipAddress1', ship_address.street1);
    setValue('shipAddress2', ship_address.street2);
    setValue('shipZipcode', formatZipCode(ship_address.zipcode));
    await trigger();
    return Promise.resolve();
  } catch (e) {
    return Promise.reject();
  }
};

/**
 * Appropriately formatted payment information for what the Spree
 * endpoint expects
 *
 * @param details
 * @returns the necessary payment details for a PayPal purchase
 */
export const formatPayPalPaymentDetails = (
  details: CaptureOrderResponseBody,
): PayPalPaymentDetails => ({
  payment_method: PaymentMethod.PayPal,
  payment_id: details.id,
  payer_id: details.payer.payer_id,
});

export const formatPayPalSubscriptionPaymentDetails = (
  details: PayPalSubscriptionDetails,
): PayPalSubscriptionPaymentDetails => ({
  billing_agreement_id: details.id,
  payment_id: details.payment_id,
  payment_method: PaymentMethod.PayPal,
});

/**
 * Update the form data based on the PayPal Subscription Order API response
 *
 * @param data The response body from PayPal on successful subscription order creation.
 * @returns void
 */
export const updatePayPalSubscriptionFormData = async (
  data: any,
  setValue: Function,
): Promise<void> => {
  try {
    // Payer Data
    const email = data.subscriber.email_address || '';
    const firstName = data.subscriber.name?.given_name || '';
    const lastName = data.subscriber.name?.surname || '';
    setValue('email', email);
    setValue('shipFirstName', firstName);
    setValue('shipLastName', lastName);

    // Shipping Address (we don't bother with billing)
    const ship_address = {
      country: data.subscriber.shipping_address.address.country_code || '',
      state: data.subscriber.shipping_address.address.admin_area_1 || '',
      city: data.subscriber.shipping_address.address.admin_area_2 || '',
      street1: data.subscriber.shipping_address.address.address_line_1 || '',
      street2: data.subscriber.shipping_address.address.address_line_2 || '',
      zipcode: data.subscriber.shipping_address.address.postal_code || '',
    };

    setValue('shipState', formatAbbreviationToState(ship_address.state));
    setValue('shipCity', ship_address.city);
    setValue('shipAddress1', ship_address.street1);
    setValue('shipAddress2', ship_address.street2);
    setValue('shipZipcode', ship_address.zipcode);
    return Promise.resolve();
  } catch (e) {
    return Promise.reject();
  }
};
