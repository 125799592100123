import React, { useEffect } from 'react';
import smoothScroll from 'smoothscroll-polyfill';

import * as S from './styles';

export type AnchorLinkProps = {
  offset?: string;
  children: React.ReactNode;
  onClick?: (e: React.SyntheticEvent<HTMLElement>) => void;
  href: string;
};

export const AnchorLink: React.FC<AnchorLinkProps> = ({
  offset,
  children,
  onClick,
  href,
  ...rest
}) => {
  useEffect(() => {
    smoothScroll.polyfill();
  }, []);

  const scrollToAnchorContent = (e: React.SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    const target = e.target as HTMLElement;
    target.closest('a')?.blur();
    let scrollOffset = () => 0;

    if (typeof offset !== 'undefined') {
      scrollOffset = () => parseInt(offset);
    }

    if (href) {
      const $anchorContent = document.getElementById(`${href.slice(1)}`);
      if ($anchorContent) {
        const offsetTop =
          $anchorContent.getBoundingClientRect().top + window.pageYOffset;
        window.scroll({
          top: offsetTop - scrollOffset(),
          behavior: 'smooth',
        });
      }
    }
    if (onClick) {
      onClick(e);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLAnchorElement>) => {
    if (e.charCode === 13) {
      scrollToAnchorContent(e);
    }
  };

  return (
    <S.A
      tabIndex={0}
      {...rest}
      onClick={scrollToAnchorContent}
      onKeyPress={handleKeyPress}
    >
      {children}
    </S.A>
  );
};

export default AnchorLink;
