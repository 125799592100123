import { Col, H1, H3, useHooks } from '@everlywell/leaves';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import {
  dataLayerCheckout,
  dataLayerPurchase,
  dataLayerCaptureUserAttributes,
  dataLayerReceiveMarketing,
  mixpanelPurchase,
  mixpanelValidationErrors,
  mixpanelPurchaseError,
} from 'components/Checkout/AnalyticsHelpers';
import {
  processOrder,
  cleanupOrder,
  redirectToConfirmation,
  buildCheckoutData,
  fetchOrderFromApi,
  fetchOrderFromApiByRegistrationToken,
  getBundledParams,
  completeOrder,
  addPromoDiscountToOrder,
  addGiftCardToOrder,
  removeDiscountFromOrder,
  removeGiftCardFromOrder,
  formatAbbreviationToState,
  isZeroBalance,
  isEmptyOrder,
  initGoogleAutocomplete,
  addBundledItems,
  stripUnsafeChars,
  fillAddressFromApi,
  updateFailedOrderGuestToken,
  hasSelectedPscLocation,
} from 'components/Checkout/checkoutHelpers';
import DisclosureSection from 'components/Checkout/DisclosureSection';
import LoadingAnimation from 'components/Checkout/LoadingAnimation';
import OrderInfo from 'components/Checkout/OrderInfo';
import OrderLineItem from 'components/Checkout/OrderLineItem';
import PaymentInfo from 'components/Checkout/PaymentInfo';
import PayPalSection from 'components/Checkout/PayPalSection';
import { PlaceOrderButton } from 'components/Checkout/PlaceOrderButton';
import ShippingInfo from 'components/Checkout/ShippingInfo';
import { getStripePaymentDetails } from 'components/Checkout/StripeHelpers';
import {
  Order,
  LineItem,
  PaymentDetails,
  PaymentMethod,
  ErrorResponse,
  PSCLocation,
  CheckoutFormData,
  CheckoutPageCopy,
  CheckoutData,
  PaymentData,
} from 'components/Checkout/types';
import { useCheckoutContext } from 'components/Checkout/useCheckoutContext';
import { Notification } from 'components/Notification';
import SectionWrapper from 'components/SectionWrapper';
import ShippingSelection from 'components/ShippingSelection';
import StripePayment from 'components/Stripe/StripePayment';
import { navigate } from 'gatsby-link';
import { useFlags } from 'gatsby-plugin-launchdarkly';
import React, { useEffect, useState, useContext, SyntheticEvent } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
import ewAnalytics from 'utils/analytics';
import { getNYProducts, NyProduct } from 'utils/api/productsApi';
import { getShippingMethodId, saveOrder } from 'utils/cartHelpers';
import { ANALYTICS } from 'utils/constants/analytics';
import { CHECKOUT } from 'utils/constants/dataTest';
import { Flags } from 'utils/constants/featureFlags';
import { logError, membershipIntakeUrl } from 'utils/helpers';
import { includesCovidDTC } from 'utils/helpers/covid';
import {
  membershipLineItem,
  isMembershipVariantId,
} from 'utils/helpers/membershipHelpers';
import { trackJustUnoConversion } from 'utils/helpers/thirdPartyScripts';
import { UserContext } from 'utils/hooks/useUserContext/context';
import { subscribeSms } from 'utils/iterableSubscribe';

import * as S from '../../components/Checkout/styles';
import { skusForMixpanel } from '../../utils/analytics';
import {
  hasLabVisitItemInCart,
  hasSubscriptionItemInCart,
} from '../../utils/helpers/paymentHelpers';
import LabSection from './LabSection/index';

type Props = {
  orderId: number;
  checkoutCopy: CheckoutPageCopy;
};

export const EXISTING_ITEMS_TEXT =
  'Please note there are previously added items in your cart.';

export const CheckoutContainer: React.FC<Props> = ({
  orderId,
  checkoutCopy,
}: Props) => {
  const {
    legalNotice,
    legalNoticeLink,
    ageRestriction,
    shippingMethods,
    paymentText,
    smsLegalText,
    hdyhauDropdownOptions,
  } = checkoutCopy;

  const flags = useFlags();
  const stripe = useStripe();
  const elements = useElements();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasDiffBilling, setHasDiffBilling] = useState(false);
  const [pageError, setPageError] = useState('');
  const [pageNotification, setPageNotification] = useState('');
  const [order, setOrder] = useState<Order>();
  const [showAltPayments, setShowAltPayments] = useState(true);
  const [selectedPsc, setSelectedPsc] = useState<PSCLocation>({
    id: '',
    name: '',
    distance: 0,
    address: '',
    city: '',
    state: '',
    zip: '',
    hours: '',
  });
  // Has at least one lab visit item in cart
  const [hasLabVisit, setHasLabVisit] = useState(
    hasLabVisitItemInCart(order?.line_items),
  );
  // Has only lab visit items
  const [hasOnlyLabVisit, setHasOnlyLabVisit] = useState(
    order?.line_items?.every((item) => item.is_lab_visit),
  );
  const [hasDeliveryOptions, setHasDeliveryOptions] = useState(false);
  const [shippingInfoSectionNumber, setShippingInfoSectionNumber] = useState(1);
  const [shippingSelectionSectionNumber, setShippingSelectionSectionNumber] =
    useState(2);
  const [paymentInfoSectionNumber, setPaymentInfoSectionNumber] = useState(2);
  const [orderSummarySectionNumber, setOrderSummarySectionNumber] = useState(4);
  const [shippingInfoSectionTitle, setShippingInfoSectionTitle] = useState(
    'Shipping information',
  );
  const [isApplyingPromo, setIsApplyingPromo] = useState(false);
  const [orderIdParam] = useQueryParam('orderId', NumberParam);
  const [registrationToken] = useQueryParam('registration_token', StringParam);
  const [failedOrderParam] = useQueryParam('failedOrder', StringParam);
  // use-query-params expects booleans as 1/0, but our links already use "true"
  const failedOrder = failedOrderParam === 'true';

  const checkoutContext = useCheckoutContext();

  const { isMobile } = useHooks.useWindowSize();

  // Two-step destructure so we can pass all of the form methods to the provider
  const useFormMethods = useForm<CheckoutFormData>({
    defaultValues: {
      marketing: true,
      smsOptIn: false,
    },
  });

  const { register, handleSubmit, errors, setValue } = useFormMethods;

  const {
    userData: { userEmail },
  } = useContext(UserContext);

  // TODO: Clean this up once feature is enabled in prod
  const [expressShippingQueryParam] = useQueryParam<string>('express-shipping');

  // Flag description in https://app.launchdarkly.com/default/development/features/express-shipping/settings
  const hasExpressShipping =
    flags[Flags.ExpressShipping] || expressShippingQueryParam === 'true';

  const redirectToMemberIntake = (variant_id: number) => {
    window.location.href = membershipIntakeUrl(variant_id);
  };

  // We want to redirect users with memberships in their carts to the new membership intake
  // experience. We also clear out the current order from localstorage.
  useEffect(() => {
    if (!order) return;

    const lineItem = membershipLineItem(order);
    if (lineItem) {
      cleanupOrder();
      redirectToMemberIntake(lineItem.variant_id);
    }
  }, [order]);

  useEffect(() => {
    if (!order) return;

    setHasOnlyLabVisit(!!order?.line_items?.every((item) => item.is_lab_visit));
    setShippingInfoSectionTitle('Billing information');

    /* Should show delivery options if
      - the cart does not contain only lab visists,
      - the cart does not contain a membership
      - and the express shipping feature is enabled
    */
    const shouldShowDeliveryOptions = !hasOnlyLabVisit && hasExpressShipping;
    setHasDeliveryOptions(shouldShowDeliveryOptions);
    setHasLabVisit(hasLabVisitItemInCart(order?.line_items));
  }, [order]);

  useEffect(() => {
    if (hasOnlyLabVisit) {
      setShippingInfoSectionNumber(2);
      setPaymentInfoSectionNumber(3);
      setOrderSummarySectionNumber(4);
      setHasDeliveryOptions(false);
      return;
    }

    if (hasLabVisit && hasDeliveryOptions) {
      setShippingInfoSectionNumber(2);
      setShippingSelectionSectionNumber(3);
      setPaymentInfoSectionNumber(4);
      setOrderSummarySectionNumber(5);
      return;
    }

    if (hasDeliveryOptions) {
      setShippingInfoSectionNumber(1);
      setShippingSelectionSectionNumber(2);
      setPaymentInfoSectionNumber(3);
      setOrderSummarySectionNumber(4);
      return;
    }

    if (!hasDeliveryOptions && order) {
      /* when shipping selection is not available, for example: feature is off or
      with a membership in the cart we have to decrease the following section numbers */
      setPaymentInfoSectionNumber(2);
      setOrderSummarySectionNumber(3);
    }
  }, [hasDeliveryOptions, hasLabVisit, hasOnlyLabVisit, order]);

  useEffect(() => {
    setShippingInfoSectionTitle(
      hasOnlyLabVisit ? 'Billing information' : 'Shipping information',
    );
  }, [hasOnlyLabVisit]);

  // Track frontend errors in Mixpanel
  useEffect(() => {
    if (Object.keys(errors).length) {
      const errorMessages = [],
        errorFields = [];
      for (const error in errors) {
        // @ts-ignore
        errorMessages.push(errors[error].message);
        errorFields.push(error);
      }
      mixpanelValidationErrors(errorMessages, errorFields);
    }
  }, [errors]);

  // DataLayer checkout event on pageLoad
  useEffect(() => {
    if (order) {
      dataLayerCheckout(order);
    }
  }, [order]);

  // Fetch products available in NY
  const { data } = useQuery(`ny-products`, () => getNYProducts(), {
    cacheTime: Infinity,
    staleTime: Infinity,
  });
  const nyProductSkus = data?.map((product: NyProduct) => product.sku) ?? [];

  const orderLineItems = order?.line_items ?? [];

  // Evaluate if the order is allowed in NY
  const isOrderNotAllowedInNY = orderLineItems.some((item) => {
    if (
      !nyProductSkus.includes(item.variant_sku) &&
      !nyProductSkus.includes(item.product_sku)
    ) {
      return true;
    }
    return false;
  });

  const isAllowedInNewYork = !isOrderNotAllowedInNY;

  // Initialize Google Address Autocomplete
  useEffect(() => {
    initGoogleAutocomplete();
  }, []);

  const handleError = (err: ErrorResponse) => {
    if (typeof err === 'string') {
      setPageError(err);
    } else {
      const { response } = err;
      if (!response) {
        return;
      }
      const { data } = response;
      const userFacingError = data?.errors && data?.errors[0];
      if (userFacingError) {
        setPageError(userFacingError);
      }
    }
  };

  useEffect(() => {
    const initOrder = async () => {
      try {
        let order;

        if (orderIdParam && failedOrder && registrationToken) {
          setIsSubmitting(true);
          const pastOrder = await fetchOrderFromApiByRegistrationToken(
            orderIdParam,
            registrationToken,
          );
          if (pastOrder.data.state === 'failed') {
            order = pastOrder;
            updateFailedOrderGuestToken(order.data).then(
              (res) => (order = res),
            );
            await fillAddressFromApi(order.data);
          } else {
            navigate('/');
          }
          setIsSubmitting(false);
        } else {
          order = await fetchOrderFromApi(orderId);
        }

        const { bundleItems, promoToApply } = getBundledParams(
          stripUnsafeChars(window.location?.search),
        );

        if (bundleItems.length) {
          // Check for membership bundle items
          const membershipItem = bundleItems.find((bi) =>
            isMembershipVariantId(bi.variantId),
          );
          // Make sure sent people to the right intake if they somehow
          // get a bundle with a membership in it.
          if (membershipItem) {
            cleanupOrder();
            redirectToMemberIntake(membershipItem.variantId);
          }

          // Set up the bundle
          if (!isEmptyOrder(order?.data)) {
            setPageNotification(EXISTING_ITEMS_TEXT);
          }
          await addBundledItems(bundleItems, order?.data?.id || orderId);
          order = await fetchOrderFromApi(order?.data?.id || orderId);
        }

        if (promoToApply) {
          try {
            const u = await addPromoDiscountToOrder(orderId, promoToApply);
            setOrder(u.data);
            saveOrder(u);
            return;
          } catch (err) {
            handleError(err as ErrorResponse);
          }
        }

        setOrder(order?.data);
        saveOrder(order);
      } catch (err) {
        handleError(err as ErrorResponse);
      }
    };
    initOrder();
  }, []);

  // Pre-fill email if available
  useEffect(() => {
    if (userEmail) {
      setValue('email', userEmail);
    }
  }, [userEmail]);

  // Show alternative payment buttons (PayPal/Express Pay)
  useEffect(() => {
    const showAltPaymentButtons = !hasSubscriptionItemInCart(order?.line_items);
    setShowAltPayments(showAltPaymentButtons);
  }, [order?.line_items]);

  // Show page level error
  useEffect(() => {
    if (pageError !== '' || pageNotification !== '') {
      const interval = setInterval(() => {
        setPageError('');
        setPageNotification('');
      }, 5000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [pageError, pageNotification]);

  const handleSmsSubscription = async (formData: CheckoutData) => {
    if (formData.smsOptIn) {
      if (formData.ship_address.phone) {
        await subscribeSms(formData.ship_address.phone, formData.email);
      } else {
        ewAnalytics.track({
          event: ANALYTICS.EVENTS.SMS_SUBSCRIPTION,
          data: {
            label: 'Unsuccessful sms opt-in, due to missing phone number',
          },
        });
      }
    }
  };

  const onSuccess = async (
    { id, registration_token }: { id: number; registration_token: string },
    formData: CheckoutData,
  ) => {
    if (order) {
      dataLayerPurchase(order);
      dataLayerCaptureUserAttributes(formData);
      dataLayerReceiveMarketing(formData.marketing === '1');
      trackJustUnoConversion(order);
      await Promise.all([
        mixpanelPurchase(order),
        handleSmsSubscription(formData),
      ]);
    }
    cleanupOrder();
    redirectToConfirmation(id, registration_token);
    setIsSubmitting(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onError = (err: any) => {
    setIsSubmitting(false);
    if (err) {
      const { response } = err;
      const { data } = response || 'err';
      const failedProcessingOrder = order?.state !== 'failed';
      mixpanelPurchaseError(failedProcessingOrder, data?.errors);
      const errorMessage = (data?.errors && data.errors[0]) || err;
      logError(errorMessage, { data: response, orderId });
      // Order now has a failed state - fetch updated order and sync with local state
      fetchOrderFromApi(orderId).then((resp) => {
        setOrder(resp.data);
      });
      setPageError(data?.errors || err);
    }
  };

  const handleBillAddressToggle = (selectedOption: string) => {
    setHasDiffBilling(selectedOption === CHECKOUT.DIFFERENT_BILLING_ADDRESS_ID);
  };

  const getPaymentDetails = async (): Promise<PaymentDetails> => {
    try {
      if (checkoutContext.paymentMethod === PaymentMethod.Stripe) {
        return await getStripePaymentDetails(stripe, elements);
      } else {
        return Promise.resolve(
          checkoutContext.paymentDetails as PaymentDetails,
        );
      }
    } catch (err) {
      if (typeof err === 'string') {
        setPageError(err);
        mixpanelValidationErrors([err]);
      }
      return Promise.reject();
    }
  };

  const onSubmit = async (data: CheckoutFormData) => {
    if (order) {
      setIsSubmitting(true);
      try {
        if (hasLabVisit) {
          // An order with in-lab kits needs a Patient Service Center (PSC) selected
          // Include PSC id in check out data
          if (hasSelectedPscLocation(selectedPsc)) {
            data.psc_id = selectedPsc.id;
          } else {
            throw Error('Please enter a ZIP Code and select a lab');
          }
        }

        const checkoutData = buildCheckoutData(
          data,
          hasDiffBilling &&
            checkoutContext.paymentMethod === PaymentMethod.Stripe,
        );

        // send data to backend if the feature is enabled
        if (hasDeliveryOptions) {
          checkoutData.shipping_method_id = getShippingMethodId();
        }

        const paymentDetails = !isZeroBalance(order)
          ? await getPaymentDetails()
          : null;
        const result =
          order.state !== 'failed'
            ? await processOrder(order, checkoutData, paymentDetails)
            : await completeOrder(order, checkoutData, paymentDetails);
        onSuccess(result.data, checkoutData);
      } catch (err) {
        onError(err);
      }
    }
  };

  const updatePaymentDetails = (
    method: PaymentMethod,
    details: PaymentDetails,
  ) => {
    checkoutContext.setPaymentMethod(method);
    checkoutContext.setPaymentDetails(details);
  };

  const handleApplyPromoClick = (
    e: SyntheticEvent<HTMLInputElement>,
    promoValue: string,
  ) => {
    e.preventDefault();
    setIsApplyingPromo(true);

    ewAnalytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: ANALYTICS.LABELS.APPLY_PROMO,
        promo: promoValue,
      },
    });
    addPromoDiscountToOrder(orderId, promoValue)
      .then((res) => {
        setOrder(res.data);
        saveOrder(res);
      })
      .catch((err) => onError(err))
      .finally(() => setIsApplyingPromo(false));
  };

  const handleGiftCardApply = (
    e: SyntheticEvent<HTMLInputElement>,
    giftCardValue: string,
  ) => {
    e.preventDefault();
    ewAnalytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        label: ANALYTICS.LABELS.APPLY_GIFTCARD,
      },
    });
    addGiftCardToOrder(orderId, giftCardValue)
      .then((res) => {
        setOrder(res.data);
      })
      .catch((err) => onError(err));
  };

  const removeDiscount = () => {
    setIsApplyingPromo(true);

    removeDiscountFromOrder(orderId)
      .then((res) => {
        setOrder(res.data);
        saveOrder(res);
      })
      .catch((err) => onError(err))
      .finally(() => setIsApplyingPromo(false));
  };

  const removeGiftCard = (adjustmentId: number) => {
    removeGiftCardFromOrder(orderId, adjustmentId)
      .then((res) => {
        setOrder(res.data);
      })
      .catch((err) => onError(err));
  };

  /**
   * After confirming express pay in modal, payer data and a token
   * are issued and passed back.  This takes that data to auto-fill
   * the form and update the payment details
   *
   * @param data payer data passed back from express pay modal
   * @param token stripe token for processing device pay
   */
  const handlePaymentConfirm = (data: PaymentData, token: { id: string }) => {
    const { payerEmail, shippingAddress, payerName } = data;
    const { addressLine, city, postalCode, region } = shippingAddress;
    const [firstName, lastName] = payerName.split(' ');
    const [address1, address2] = addressLine;

    // Fill form with data from Express Pay
    setValue('email', payerEmail);
    setValue('shipFirstName', firstName);
    setValue('shipLastName', lastName);
    setValue('shipState', formatAbbreviationToState(region));
    setValue('shipCity', city);
    setValue('shipAddress1', address1);
    setValue('shipAddress2', address2);
    setValue('shipZipcode', postalCode);

    // Set payment details for Device / Express Pay
    updatePaymentDetails(PaymentMethod.Device, {
      stripe_token: token.id,
      // Payment method should still be stripe
      payment_method: PaymentMethod.Stripe,
    });
  };

  const orderTotalInCents = Math.round(parseFloat(order?.total || '0') * 100);
  const orderNotEmpty = order?.line_items && order.line_items.length > 0;

  const lineItems = order?.line_items.map((li: LineItem) => (
    <OrderLineItem lineItem={li} key={li.variant_id} />
  ));

  const hasZeroBalance = isZeroBalance(order);

  return (
    <SectionWrapper data-testid={`checkoutContainer`}>
      <LoadingAnimation isSubmitting={isSubmitting} />
      {pageError || pageNotification ? (
        <Notification isError={!!pageError}>
          {pageError || pageNotification}
        </Notification>
      ) : null}
      <FormProvider {...useFormMethods}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={
            // add this class to be picked up by justuno and show the covid disclaimer
            includesCovidDTC(order?.line_items)
              ? 'show-justuno-covid-disclaimer'
              : ''
          }
        >
          <S.CardContent>
            <Col lg={8} md={12}>
              <H1>Secure Checkout</H1>
              <S.CardLayout>
                {showAltPayments ? (
                  <S.AltPayments
                    data-testid={`alt-payments-section`}
                    allowClick={!isApplyingPromo}
                  >
                    {!!lineItems && (
                      <S.CheckoutButton>
                        <PayPalSection
                          order={order}
                          onSuccess={updatePaymentDetails}
                          onError={onError}
                        />
                      </S.CheckoutButton>
                    )}
                    <S.CheckoutButton>
                      <StripePayment
                        cartTotal={orderTotalInCents}
                        cartNotEmpty={orderNotEmpty}
                        variantsSkus={skusForMixpanel(order?.line_items)}
                        isInCheckout={true}
                        label={ANALYTICS.LABELS.EXPRESS_PAY_CHECKOUT}
                        handlePaymentConfirm={handlePaymentConfirm}
                      />
                    </S.CheckoutButton>
                  </S.AltPayments>
                ) : null}

                {hasLabVisit ? (
                  <LabSection
                    sectionNumber={1}
                    setSelectedPsc={setSelectedPsc}
                    selectedPsc={selectedPsc}
                    errors={errors}
                    register={register}
                  />
                ) : null}

                <ShippingInfo
                  errors={errors}
                  register={register}
                  disableEmail={!!userEmail}
                  disableInput={isSubmitting}
                  sectionNumber={shippingInfoSectionNumber}
                  sectionTitle={shippingInfoSectionTitle}
                  includeNewYork={isAllowedInNewYork}
                  stateDisclaimer={checkoutCopy.stateDisclaimer}
                  smsOptInCheckboxText={checkoutCopy.smsOptInCheckbox}
                  phoneHelperText={checkoutCopy.phoneHelperText}
                />

                {hasDeliveryOptions && checkoutCopy.shippingMethods ? (
                  <ShippingSelection
                    sectionNumber={shippingSelectionSectionNumber}
                    shippingNoticeBefore={checkoutCopy.shippingNoticeBefore}
                    shippingNoticeAfter={checkoutCopy.shippingNoticeAfter}
                    shippingOptions={shippingMethods}
                  />
                ) : null}

                {!hasZeroBalance && (
                  <PaymentInfo
                    register={register}
                    onBillAddressToggle={handleBillAddressToggle}
                    diffBillAddressSelected={hasDiffBilling}
                    errors={errors}
                    hidePaymentFields={
                      checkoutContext &&
                      checkoutContext.paymentMethod !== PaymentMethod.Stripe
                    }
                    disabledInput={isSubmitting}
                    sectionNumber={paymentInfoSectionNumber}
                    hasOnlyLabVisit={hasOnlyLabVisit}
                    includeNewYork={isAllowedInNewYork}
                    stateDisclaimer={checkoutCopy.stateDisclaimer}
                    privacyPolicyDisclaimerText={
                      checkoutCopy.privacyPolicyDisclaimer
                    }
                    paymentText={paymentText}
                    hdyhauDropdownOptions={hdyhauDropdownOptions}
                  />
                )}
                {isMobile ? (
                  <S.ContentContainer data-testid={CHECKOUT.ORDER_SUMMARY}>
                    <H3>{orderSummarySectionNumber}. Order summary</H3>
                    <OrderInfo
                      order={order}
                      isSubmitting={isSubmitting}
                      lineItems={lineItems}
                      handleApplyPromoClick={handleApplyPromoClick}
                      handleGiftCardApply={handleGiftCardApply}
                      removeDiscount={removeDiscount}
                      removeGiftCard={removeGiftCard}
                      hideShippingInfo={hasOnlyLabVisit}
                      isApplyingPromo={isApplyingPromo}
                    />
                  </S.ContentContainer>
                ) : null}
                <PlaceOrderButton
                  isSubmitting={isSubmitting}
                  isLoading={isApplyingPromo}
                />
              </S.CardLayout>
              {isMobile && (
                <DisclosureSection
                  textBefore={legalNotice.textBefore.textBefore}
                  modalLink={legalNotice.modalLink}
                  textAfter={legalNotice.textAfter.textAfter}
                  ageRestrictionContent={ageRestriction}
                  smsLegalText={smsLegalText}
                  legalNoticeLink={legalNoticeLink}
                />
              )}
            </Col>
            <div /> {/* need this div to devide the spaces  */}
            {!isMobile ? (
              <S.OrderSummaryContainer lg={4} md={12}>
                <H3>Order Summary</H3>
                <OrderInfo
                  order={order}
                  isSubmitting={isSubmitting}
                  lineItems={lineItems}
                  handleApplyPromoClick={handleApplyPromoClick}
                  handleGiftCardApply={handleGiftCardApply}
                  removeDiscount={removeDiscount}
                  removeGiftCard={removeGiftCard}
                  hideShippingInfo={hasOnlyLabVisit}
                  isApplyingPromo={isApplyingPromo}
                />
                <DisclosureSection
                  textBefore={legalNotice.textBefore.textBefore}
                  modalLink={legalNotice.modalLink}
                  textAfter={legalNotice.textAfter.textAfter}
                  ageRestrictionContent={ageRestriction}
                  smsLegalText={smsLegalText}
                  legalNoticeLink={legalNoticeLink}
                />
              </S.OrderSummaryContainer>
            ) : null}
          </S.CardContent>
        </form>
      </FormProvider>
    </SectionWrapper>
  );
};

export default CheckoutContainer;
