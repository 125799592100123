import { Container, size, Tooltip, typography } from '@everlywell/leaves';
import styled from 'styled-components';

const LabSectionContainer = styled(Container)`
  margin-bottom: ${size.xl2}px;
  margin-top: ${size.xl3}px;
  padding: 0 ${size.md}px 0 ${size.md}px;

  h3 {
    margin-bottom: ${size.md}px;
  }

  p,
  span {
    margin-bottom: ${size.md}px;
    font-weight: ${typography.weight.book};

    span {
      font-weight: ${typography.weight.bold};
    }
  }
`;

const TooltipWrapper = styled.div`
  margin-bottom: ${size.md}px;
`;

const InfoTooltip = styled(Tooltip)`
  display: inline-block;
  margin-left: ${size.xs1}px;

  .tooltipBox {
    width: 295px;
  }
`;

export { LabSectionContainer, TooltipWrapper, InfoTooltip };
