import RichTextWrapper from 'components/RichTextWrapper';
import React, { useState } from 'react';
import ewAnalytics from 'utils/analytics';
import { ANALYTICS } from 'utils/constants/analytics';
import { CHECKOUT } from 'utils/constants/dataTest';
import { ContentfulRichText } from 'utils/contentful/renderRichText';

import AgeRestrictionModal from './AgeRestrictionModal';
import * as S from './styles';
import { AgeRestriction } from './types';

export type Props = {
  textBefore?: string;
  modalLink: string;
  textAfter?: string;
  ageRestrictionContent: AgeRestriction;
  smsLegalText?: ContentfulRichText;
  legalNoticeLink?: ContentfulRichText;
};

const DisclosureSection = ({
  textBefore,
  modalLink,
  textAfter,
  ageRestrictionContent,
  smsLegalText,
  legalNoticeLink,
}: Props): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onModalOpen = () => {
    setIsModalOpen(true);
    ewAnalytics.track({
      event: ANALYTICS.EVENTS.CLICKED_MODAL,
      data: {
        label: ANALYTICS.LABELS.AGE_RESTRICTION_MODAL,
      },
    });
  };

  const legalNoticeLinkSection = legalNoticeLink ? (
    <S.LegalNoticeLinkWrapper>
      <RichTextWrapper
        contentfulRichText={legalNoticeLink}
        data-testid={CHECKOUT.LEGAL_NOTICE_LINK}
      />
    </S.LegalNoticeLinkWrapper>
  ) : null;

  return (
    <>
      <S.DisclosureTextContainer>
        <span data-testid={CHECKOUT.DISCLOSURE_TEXT}>
          {textBefore}
          <S.AgeRestrictionModalLink onClick={() => onModalOpen()}>
            {modalLink}
          </S.AgeRestrictionModalLink>
          {textAfter} {legalNoticeLinkSection}
        </span>
      </S.DisclosureTextContainer>

      {isModalOpen ? (
        <AgeRestrictionModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          ageRestriction={ageRestrictionContent}
        />
      ) : null}

      {smsLegalText ? (
        <S.SMSLegalText>
          <RichTextWrapper
            contentfulRichText={smsLegalText}
            data-testid={CHECKOUT.SMS_LEGAL_TEXT}
          />
        </S.SMSLegalText>
      ) : null}
    </>
  );
};

export default DisclosureSection;
