import { H3, Icons } from '@everlywell/leaves';
import React from 'react';

import LabSearch, {
  Props as LabSearchProps,
} from '../../../components/PscLabSearch/LabSearch';
import * as S from './styles';

interface Props extends LabSearchProps {
  sectionNumber: number;
}

const LabSection = ({
  sectionNumber,
  selectedPsc,
  setSelectedPsc,
  register,
  errors,
}: Props): JSX.Element => {
  return (
    <S.LabSectionContainer>
      <H3>{sectionNumber}. Preview labs near you*</H3>
      <p>
        This test requires a sample collected in a Quest lab. Use our lab
        selector below to confirm there’s a lab near you before you buy.
      </p>
      <p>
        When your lab request is approved, you won’t be limited to this lab—you
        can schedule an appointment at <span>any Quest lab </span> near you.
        Look out for an email with more information soon.
      </p>
      <S.TooltipWrapper>
        <span>
          *In-lab collection not available in New York, New Jersey, or Rhode
          Island.
        </span>
        <S.InfoTooltip
          animationSpeed="normal"
          content="Unavailable due to state's regulations around testing"
          position="top"
          arrow="right"
          tooltipBoxClass="tooltipBox"
        >
          <Icons.InformationV2 />
        </S.InfoTooltip>
      </S.TooltipWrapper>

      <LabSearch
        selectedPsc={selectedPsc}
        setSelectedPsc={setSelectedPsc}
        register={register}
        errors={errors}
      />
    </S.LabSectionContainer>
  );
};

export default LabSection;
