import {
  colors,
  size,
  mediaQueries,
  Container,
  typography,
} from '@everlywell/leaves';
import styled from 'styled-components';

const ShippingSelectionContainer = styled(Container)`
  padding: 0 ${size.md}px;

  h3 {
    padding-bottom: ${size.md}px;
  }

  label {
    display: block;
    font-weight: ${typography.weight.book};
    margin-bottom: ${size.md}px;

    a {
      color: ${colors.green5};
      font-weight: ${typography.weight.bold};
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline;
    }

    ${mediaQueries.forTabletHorizontalUp} {
      margin-top: ${size.md}px;
    }
  }

  .col {
    display: flex;
  }

  .radio-button {
    border: 1px solid ${colors.green2};
    box-shadow: 4px 4px 15px -5px rgb(0 0 0 / 10%);
    margin-bottom: ${size.md}px;
    background: ${colors.white};
    display: flex;
    height: auto;
    width: 100%;

    ${mediaQueries.forTabletHorizontalUp} {
      margin-bottom: 0;
    }

    label {
      width: 100%;
      height: 100%;
      margin: 0;
      padding: ${size.md}px;
      display: flex;

      > div {
        width: 100%;
      }
    }

    label::after {
      margin-left: ${size.md}px;
    }

    &.selected {
      background-color: ${colors.green1};
    }
  }
`;

const ShippingOptionContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  div {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .shipping-method {
    font-weight: ${typography.weight.regular};
  }

  .arrives-by {
    font-size: ${Number(size.md) - 2}px;
  }
`;

export { ShippingOptionContent, ShippingSelectionContainer };
