import { Col, H3, RadioButton, Row } from '@everlywell/leaves';
import { ShippingMethodCopy } from 'components/Checkout/types';
import React, { useEffect, useState } from 'react';
import ewAnalytics from 'utils/analytics';
import { getShippingMethodId, setShippingMethodId } from 'utils/cartHelpers';
import { ANALYTICS } from 'utils/constants/analytics';
import { CHECKOUT } from 'utils/constants/dataTest';
import { SHIPPING } from 'utils/constants/shipping';
import { addWeekDays, getWeekDay, getDaysToDelivery } from 'utils/dateHelpers';
import * as S from './styles';

const TEN_MINUTES_IN_MS = 10 * 60 * 1000;

export type Props = {
  sectionNumber: number;
  shippingNoticeBefore: string;
  shippingNoticeAfter: string;
  shippingOptions: ShippingMethodCopy[];
};

const ShippingSelection = ({
  sectionNumber,
  shippingNoticeBefore,
  shippingNoticeAfter,
  shippingOptions,
}: Props): JSX.Element => {
  const [selected, setSelected] = useState(
    getShippingMethodId() || SHIPPING.STANDARD,
  );

  const twoDaysFromNow = addWeekDays(getDaysToDelivery());
  const [, setArrivesInWeekDay] = useState(getWeekDay(twoDaysFromNow));

  useEffect(() => {
    const updateTimeLineInterval = setInterval(() => {
      const newTwoDaysFromNow = addWeekDays(getDaysToDelivery());
      setArrivesInWeekDay(getWeekDay(newTwoDaysFromNow));
    }, TEN_MINUTES_IN_MS);

    return () => {
      clearInterval(updateTimeLineInterval);
    };
  }, []);

  const trackShippingSelection = (shippingMethodId: string) => {
    const shippingMethod =
      shippingMethodId === SHIPPING.EXPRESS
        ? ANALYTICS.LABELS.EXPRESS_SHIPPING
        : ANALYTICS.LABELS.STANDARD_SHIPPING;

    ewAnalytics.track({
      event: ANALYTICS.EVENTS.SHIPPING_METHOD_SELECTED,
      data: {
        label: shippingMethod,
      },
    });
  };

  const handleOptionChange = (e: React.FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLElement;
    setSelected(target.id);
    setShippingMethodId(target.id);
    trackShippingSelection(target.id);
  };

  const StandardShippingContent = (
    <S.ShippingOptionContent data-testid="standard-shipping">
      <div>
        <span className="shipping-method">{shippingOptions[0].name}</span>
        <span>FREE</span>
      </div>
      <div className="arrives-by">
        Arrives in {shippingOptions[0].minBusinessDays}-
        {shippingOptions[0].maxBusinessDays} business days
      </div>
    </S.ShippingOptionContent>
  );

  const ExpressShippingContent = (
    <S.ShippingOptionContent data-testid="express-shipping">
      <div>
        <span className="shipping-method">{shippingOptions[1].name}</span>
        <span>+${SHIPPING.EXPRESS_FEE}</span>
      </div>
      {/* Commenting this out temporarily to accommodate Christmas holiday change */}
      {/* <div className="arrives-by">Arrives by {arrivesInWeekDay}</div> */}
      <div className="arrives-by">
        Arrives in {shippingOptions[1].minBusinessDays}-
        {shippingOptions[1].maxBusinessDays} business days
      </div>
    </S.ShippingOptionContent>
  );

  return (
    <S.ShippingSelectionContainer>
      <H3>{sectionNumber}. Delivery Options</H3>
      <label>{shippingNoticeBefore} </label>

      <Row>
        <Col xs md>
          <RadioButton
            id={SHIPPING.STANDARD}
            name="shippingMethod"
            label={StandardShippingContent}
            checked={selected === SHIPPING.STANDARD}
            onChange={handleOptionChange}
            className={
              selected === SHIPPING.STANDARD
                ? 'radio-button selected'
                : 'radio-button'
            }
          />
        </Col>
        <Col xs md>
          <RadioButton
            id={SHIPPING.EXPRESS}
            name="shippingMethod"
            label={ExpressShippingContent}
            checked={selected === SHIPPING.EXPRESS}
            onChange={handleOptionChange}
            className={
              selected === SHIPPING.EXPRESS
                ? 'radio-button selected'
                : 'radio-button'
            }
          />
        </Col>
      </Row>

      <label data-testid={CHECKOUT.SHIPPING_DISCLAIMER}>
        {shippingNoticeAfter}
      </label>
    </S.ShippingSelectionContainer>
  );
};

export default ShippingSelection;
