import RichTextWrapper from 'components/RichTextWrapper';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import { ContentfulRichText } from 'utils/contentful/renderRichText';

import * as S from './styles';

type AcceptedCardsProps = {
  paymentText?: ContentfulRichText;
  cards: {
    title: string;
    gatsbyImageData: IGatsbyImageData;
  }[];
};

export const AcceptedCards = ({ cards, paymentText }: AcceptedCardsProps) => (
  <S.PaymentMethods xs>
    <S.CustomGatsbyImageWrapper>
      {cards.map((card, idx) => (
        <S.CustomGatsbyImage
          key={`${card.title}-${idx}`}
          image={card.gatsbyImageData}
          alt={card.title}
        />
      ))}
    </S.CustomGatsbyImageWrapper>
    <S.PaymentMethodTextWrapper>
      {paymentText && <RichTextWrapper contentfulRichText={paymentText} />}
      <S.PaymentMethodText></S.PaymentMethodText>
    </S.PaymentMethodTextWrapper>
  </S.PaymentMethods>
);
